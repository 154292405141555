////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import AdminModulePostWrap from 'pages/lobby/space/sm_admin/AdminModulePostWrap';
import AdminModuleUserWrap from 'pages/lobby/space/sm_admin/AdminModuleUserWrap';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoAnimate from 'assets/logoAnimate.svg';
import logoW from 'assets/logoW.svg';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function SpaceModuleAdmin({space,fileList,getFileList,loading}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [SelectContent, setSelectContent] = useState('dashboard'); 
    const [FileFilter, setFileFilter] = useState(null); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {

    //     return () => {
    //     };
    //   },[]);

    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     setTimeout(function() {
    //         getFileList()
    //       }, 1000);
    //     // Cleanup function (optional)
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[props.mode]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const getAdminModule = () => {

        switch (SelectContent) {
            case 'dashboard':
            return(
                <div className='logo_placeholder'>
                    <img src={logoAnimate} width={200} height={200}  alt="Logo Animation" />
                    <h3>준비중인 페이지 입니다</h3>
                </div>
            )
            case 'server':
            return(
                <div className='logo_placeholder'>
                    <img src={logoAnimate} width={200} height={200}  alt="Logo Animation" />
                    <h3>준비중인 페이지 입니다</h3>
                </div>
            )
            case 'post':
            
            return (<AdminModulePostWrap/>)    
            case 'user':
            
            return (<AdminModuleUserWrap/>)    
            default:
                break;
        }

    }
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <div className='SpaceModuleContainer AdminContainer' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='spaceField'>
        <nav id='AdminNav' >
            <div className='categoryList'>
                    <div className='categoryContainer' onClick={()=>setSelectContent('dashboard')}>
                    <button 
                    className={SelectContent == 'dashboard' ?'selectCategory category':'category'}>
                        {Editor.icon({icon:'team_dashboard',size:20,lineHeight:30})}
                        &nbsp;
                        모와보기
                    </button>
                    </div>
                    <div className='categoryContainer' onClick={()=>setSelectContent('server')}>
                    <button className={SelectContent == 'server' ?'selectCategory category':'category'}>
                        {Editor.icon({icon:'router',size:20,lineHeight:30})}
                        &nbsp;
                        서버
                    </button>
                    </div>
                    <div className='categoryContainer' onClick={()=>setSelectContent('post')}>
                    <button className={SelectContent == 'post' ?'selectCategory category':'category'}>
                        {Editor.icon({icon:'post',size:20,lineHeight:30})}
                        &nbsp;
                        게시글
                    </button>
                    </div>
                    <div className='categoryContainer' onClick={()=>setSelectContent('user')}>
                    <button className={SelectContent == 'user' ?'selectCategory category':'category'} >
                        {Editor.icon({icon:'group',size:20,lineHeight:30})}
                        &nbsp;
                        유저
                    </button>
                    </div>
                    {/* <button className='category'>
                        {Editor.icon({icon:'mic',size:20,lineHeight:30})}
                        &nbsp;
                        스트리밍
                    </button> */}
            </div>

        </nav>
        <div  className='AdminModulContent'>
            {getAdminModule()}
        </div>
        </div>
       
  
    </div>
    );
  }

  export default SpaceModuleAdmin;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminContainer {
        width: 100%;
        flex: 1;
    }

`;

const ContainerStyle = `

  .AdminContainer .spaceField{
        display: flex;
        width: 100%; 
        height: 100%;
        flex-direction: column;
        background-color: #2D2D2D;
    }

    .AdminContainer .AdminModulContent{
        display: flex;
        width: 100%; 
        height: clac( 100% - 100px);
        padding-top : 100px;
    }
    .AdminContainer .AdminModulContent .logo_placeholder{
        display : flex;
        flex-direction: column;
        width : 100%;
        height : 100%;
        min-height : 500px;
        align-items: center;
        justify-content: center;
    }
    .AdminContainer .AdminModulContent .logo_placeholder h3{
        margin-top : 20px;
        font-size : 20px;
    }

   #AdminNav{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
    #OverlapMain{
        display: flex;
        width: 100%; 
        height: 100%;
    }
    #AdminNav .upload {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
        transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    }


    #AdminNav .upload:active{
        transform: scale(0.80);
    }

    #AdminNav .fileUploadButton{
        display: flex;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
    }
    #AdminNav .backButton {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        border-radius: 50px;
    }
    #AdminNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
        transition: transform 0.1s ease-out, opacity 0.1s ease-out;
        opacity: 0.4;
    }

    #AdminNav .category:active{
        transform: scale(0.80);
        opacity : 1;
    }

    #AdminNav .selectCategory{
        opacity: 1;
    }

    #AdminNav .categoryList{
        display: flex;
    }



`;
