////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import YouTube from 'react-youtube';

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 서비스 연결
import * as Widget from 'service/edit/WidgetService' ; 
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService' ; 
import { widget } from 'service/value/Model';

// 컴포넌트
import ErrorBoundary from 'components/common/container/ErrorBoundary' ;
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function VideoV2({keyValue,pageKey,alias,type,key,id,uuid,getStyle,getAnimation,animation,parents,data}){


    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////


    // set
    const WidgetRef = useRef(null);
    const CacheRef = useRef(null); // currentTime을 저장할 ref 생성
    const context = useContext(EditContext);

    const LiveRef = useRef(false);
    /* 모든 위젯에서만 사용되는 고유 상수 */
    const Style = getStyle(data) // Style | 스타일을 가지고 옵니다.
    const Animation = getAnimation(animation,data) // Animation | 애니메이션을 가지고 옵니다.
    const MergeStyle = Widget.styleMerge(Style,Animation); // MergeStyle | 스타일과 애니메이션을 합칩니다.


    const WidgetActivate  = Widget.activate(data); // WidgetActivate | 위젯이 숨김상태인지 아닌지 판단합니다.
    const Width = data?.size?.width // Width | 위젯의 가로 사이즈
    const Height =data?.size?.height // Height | 위젯의 세로 사이즈
    const Copyright = data?.value?.copyright // copyright | 저작권 보호모드 여부
    const VideoLoop = data?.value?.loop // loop | 비디오 반복 여부
    const Volume = data?.value?.volume // volume | 볼륨 반복 여부

    /* 해당 위젯에서만 사용되는 고유 상수 */
    const MediaSource = data.value.mediaSource

    const [playerReady, setPlayerReady] = useState(false);
    const [Progress, setProgress] = useState('load');


    const [VideoType, setVideoType] = useState(() => data.func.getVideoType(MediaSource));
    const prevVideoTypeRef = useRef(VideoType);
    let iframeStyle = ``;
    const [Cache,setCache] = useState(null);

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    useEffect(() => {
        console.log('VideoType 타입 : ',VideoType)
        return () => {
            destructor(VideoType)
        };
      }, [VideoType]);

      useEffect(() => {
        setPlayerReady(false)
        const currentVideoType = data.func.getVideoType(MediaSource);
        if (prevVideoTypeRef.current !== currentVideoType) {
        destructor(prevVideoTypeRef.current)
          handleChange(prevVideoTypeRef.current, currentVideoType);
        }
        prevVideoTypeRef.current = currentVideoType; // 이전 값을 업데이트합니다

        constructor(currentVideoType)
        setVideoType(currentVideoType); // 현재 값을 업데이트합니다
      }, [MediaSource]);


        useEffect(() => {
        const intervalId = setInterval(() => {
        if (WidgetRef.current) {
            videoProtect()
        }
        }, 1000); // 1초 간격으로 상태 확인

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 인터벌 정리
    }, []);

    useEffect(() => {
        if (playerReady) {
            // youtubeMove(Cache?.playCache_progress);
        }
    }, [Cache?.playCache_progress, playerReady]);


    useEffect(() => {
        if (playerReady) {
            youTubeControl();
        }
    }, [playerReady]);



    useEffect(() => {
        if (playerReady) {
            if(parents=='broadcast'){
                WidgetRef.current.unMute();
                const VolumeValue = normalizeValue(Number(Volume),15)
                WidgetRef.current.setVolume(Number(VolumeValue));
                console.log('비디오 플레이어 사운드 체크소리 ',Number(VolumeValue),WidgetRef.current.getVolume(),WidgetRef.current.isMuted())
            }
        }
    }, [Volume]);
    

      // 캐쉬 체커
      useEffect(() => {
        initCacheControl();

      }, [context?.channelCache]);


      useEffect(() => {
        youTubeControl();
      }, [Cache?.playCache_state]);


      useEffect(() => {
        youtubeMove(Cache?.playCache_progress)
      }, [Cache?.playCache_progress]);


      // 비디오의 상태를 추적하고, 상태 변화가 감지될때 이벤트를 실행시킵니다.


    const initCacheControl = () => {
        const ResponseCache = context?.getChannelCache({'alias':alias,'type':type,'uuid':uuid})??{}
        CacheRef.current = ResponseCache;
        setCache(ResponseCache)
    }


    /**
     * 비디오 위젯 재생에 오류가 생기면 보정해주는 함수입니다
     * - 오토플레이가 정책상 막힐때 음소거로 재생하기 
     * - 캐쉬에서 가지고 온 타임라인과 다를때 보정하기
     */
    
    const videoProtect = () =>{
        const videoDuration = WidgetRef.current.getDuration();
        const PlayerState = WidgetRef.current.getPlayerState();
        const PlayTime = WidgetRef.current.getCurrentTime();
        const SetTime = Math.floor(Date.now() / 1000) - CacheRef.current.playCache_start + (CacheRef.current.playCache_progress ? CacheRef.current.playCache_progress : 0)

        const SetState = CacheRef.current.playCache_state;
        const SetDuration = CacheRef.current.playCache_duration;

        if(SetState=='play' &&  PlayerState == 1) {

            if(LiveRef.current)  {
                // 라이브 인 경우
            }
       
            else if(SetDuration < SetTime) {
                WidgetRef.current.seekTo(SetDuration);
       
            }
    
            else if(SetTime - PlayTime > 10){
                WidgetRef.current.seekTo(SetTime);
            }
        }

        if(SetState!='play' &&  PlayerState == 1) {
            youtubeStop();
        }



    }


      const youTubeControl = () => {
        if (!playerReady) return;

        switch (Cache?.playCache_state) {
            
            case 'play':
                youtubePlay();
                youtubeMove(Cache?.playCache_progress??0)
                break;
            case 'stop':
                youtubeStop();
                break;

            case 'pause':
                youtubePause();
                break;
        
            default:
                break;
        }
    }

      const constructor = (type) => {
        switch (type) {
            case 'youtube':
                
                break;
        
            default:
                break;
        }

      }
      const destructor = (type) => {

        switch (type) {
            case 'youtube': 
                if (WidgetRef.current) {
                    WidgetRef.current.destroy();
                }
                break;
        
            default:
                break;
        }
      }

    const onReady = (event) => {

        console.log('비디오 준비 완료',event.target)

        const duration = event.target.getDuration();

        if(duration == 0){
            LiveRef.current = true;
        }else{
            LiveRef.current = false;
        }
        WidgetRef.current = event.target;
        setPlayerReady(true);
        // console.log('youTubeControl-1',playerReady)

 
        // setYoutubeState('play');
        // WidgetRef.current.destroy();
    };

    const onPlay = () => {

        const VolumeValue = normalizeValue(Number(Volume),15)
        console.log('비디오 플레이어 사운드 체크',parents,VolumeValue,WidgetRef.current)
        if(parents=='broadcast'){
            WidgetRef.current.setVolume(Number(VolumeValue));
            console.log('비디오 플레이어 사운드 체크2',WidgetRef.current.getVolume(),WidgetRef.current.isMuted())
            
        }else{
            WidgetRef.current.mute();
        }
      

        console.log('youtube start:');
        setProgress('start')
    };

    const onEnd = () => {
        console.log('youtube onEnd:');
        setProgress('end')
    };

    const youtubePause = () => {
        if (WidgetRef.current && playerReady) {
            if(WidgetRef.current?.playerInfo?.videoData?.video_id){
                WidgetRef.current.pauseVideo();
            }
        }
    };

    const youtubeMove = () => {
        
        if (WidgetRef.current && playerReady) {
            console.log('WidgetRef',WidgetRef.current?.playerInfo?.videoData?.video_id,playerReady)
            if(WidgetRef.current?.playerInfo?.videoData?.video_id){
                console.log('실행됨 : ',WidgetRef.current)
                if(Cache?.playCache_state != 'stop'&&Cache?.playCache_progress!=null){
                    const VolumeValue = normalizeValue(Number(Volume),15)
                    WidgetRef.current.setVolume(Number(VolumeValue));
                    WidgetRef.current.seekTo(Cache?.playCache_progress);
                }
            }
        }
    };

    const youtubeStop = () => {
        if (WidgetRef.current)  {
            WidgetRef.current.stopVideo();
            setProgress('stop')
        }
    };

    const youtubePlay = () => {
        try {
            if (WidgetRef.current)  {
                const VolumeValue = normalizeValue(Number(Volume),15)
                WidgetRef.current.setVolume(Number(VolumeValue));
                WidgetRef.current.unMute();
                WidgetRef.current.playVideo();

                setTimeout(() => {
                    
                    if( WidgetRef.current.getPlayerState() == -1){
                        WidgetRef.current.mute();
                        WidgetRef.current.playVideo();
                    }
                    
                }, 1000);
          
            }
        } catch (error) {
            
        }
       
    };
    

    const normalizeValue = (value, maxValue) => {
        if (maxValue <= 0) {
          throw new Error('maxValue must be greater than 0');
          return 0
        }
        
        // Clamp the value between 0 and maxValue
        value = Math.max(0, Math.min(value, maxValue));
      
        // Normalize the value to a 0-100 scale
        const normalizedValue = (value / maxValue) * 100;
      
        // Return the result as an integer
        return Math.round(normalizedValue);
      }

    const handleChange = (prevType, currentType) => {
        console.log(`youtube Video type changed from ${prevType} to ${currentType}`);
        // 여기에 실행할 함수를 작성하세요
      };

      const onPlaybackRateChange = (event) => {
        console.log('youtube 재생 속도 변경:', event.data);
      };
    
      const onPlaybackQualityChange = (event) => {
        console.log('youtube 재생 품질 변경:', event.data);
      };
    
      const onError = (event) => {
        console.error('youtube 오류 발생:', event.data);
      };
    
      const onApiChange = (event) => {
        console.log('youtube API 변경됨');
      };


      const onStateChange = (event) => {
        if(Cache?.playCache_state != 'Pause'){
            if(event.data == 2){
                console.log('youtube 가짜로 의심됨');
            }
        }
        console.log('youtube 플레이어 상태 변경:', event.data);
      };

    ////////////////////////////////////////////////////////
    // Func
    ////////////////////////////////////////////////////////
    
    const getPreview = () => {
        const MinSize = Width<Height?Width:Height;
        switch (VideoType) {
            case 'youtube':
                const YoutubeID = data.func.getYoutubeID(MediaSource)
                return (
                <img className='YTOBJ' src={`https://img.youtube.com/vi/${YoutubeID}/maxresdefault.jpg`} 
                width={Width} 
                height={Height} 
                style={{overflow:'hidden'}}
                />
                );
            default:
                return(
                    <div className={"object unknown"} style={{...MergeStyle.object,'backgroundColor':'#444444','borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column','color':'#fff'}}>
                    {MinSize < 200 ?
                    <Fragment>
                    {Editor.icon({icon:'link_off',size:MinSize/2,lineHeight:MinSize/2})}
                    </Fragment>
                    :
                    <Fragment>
                    {Editor.icon({icon:'link_off',size:MinSize/5,lineHeight:MinSize/5})}
                    <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                        동영상의 경로가 잘못되었습니다.
                    </p>
                    </Fragment>
                    }
                    </div>
                )
        }
    }

    const getVideo = () => {
        const MinSize = Width<Height?Width:Height;
        switch (VideoType) {
            case 'youtube':
                return (getYoutube());
            default:
                return(
                    <div className={"object unknown"} style={{...MergeStyle.object,'backgroundColor':'#444444','borderRadius':'10px','display':'flex','justifyContent':'center','align-items': 'center','flexDirection': 'column','color':'#fff'}}>
                    {MinSize < 200 ?
                    <Fragment>
                    {Editor.icon({icon:'link_off',size:MinSize/2,lineHeight:MinSize/2})}
                    </Fragment>
                    :
                    <Fragment>
                    {Editor.icon({icon:'link_off',size:MinSize/5,lineHeight:MinSize/5})}
                    <p style={{'fontSize': MinSize/20,'margin': MinSize/30,}}>
                        동영상의 경로가 잘못되었습니다.
                    </p>
                    </Fragment>
                    }
                    </div>
                )
        }
    }


    const getYoutube = () => {


        const opts = {
            width: Width,
            height: Height,
            playerVars: {
                autoplay: 0,         // 자동 재생
                controls: 0,         // 플레이어 컨트롤 표시
                showinfo: 0,         // 비디오 정보 표시 (deprecated, 무시됨)
                modestbranding: 1,   // YouTube 로고 숨기기
                rel: 0,              // 관련 동영상 표시 안 함
                iv_load_policy: 3,   // 주석 표시 안 함
                start: 0,           // 0초부터 재생 시작
                mute: 1,  // 유튜브 정책상 무조건 음소거로 시작
            },
        };

        const YoutubeID = data.func.getYoutubeID(MediaSource)
        if (!YoutubeID) {
            console.error('유효하지 않은 YouTube URL입니다.');
            return;
        }

  
        
        console.log('MergeStyle.object.',MergeStyle.object);
        return(
            <div className='youtubeWrap'>
                {Progress!='start'?
                <img className='youtubePreviewImage' src={`https://img.youtube.com/vi/${YoutubeID}/maxresdefault.jpg`} 
                style={{overflow:'hidden'}}
                />:null}
                <YouTube className='youtubeVideo' iframeClassName='youtubeVideoIframe' videoId={YoutubeID} opts={opts} onReady={onReady} onPlay={onPlay} onEnd={onEnd} onStateChange={onStateChange} 
                    onPlaybackRateChange={onPlaybackRateChange}
                    onPlaybackQualityChange={onPlaybackQualityChange}
                    onError={onError}
                    onApiChange={onApiChange}
                />
            </div>

        )
    }
    
    ////////////////////////////////////////////////////////
    // Widget lander
    ////////////////////////////////////////////////////////



    switch (parents) {
        /* 편집화면에서 보이는 위젯의 모습 */
        case 'edit':
            // 편집화면에서는 애니메이션 효과를 비활성화 합니다.
            MergeStyle.layout.transition = false
            MergeStyle.object.transition = false
            console.log('')
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle+iframeStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                    {/* 비디오 위젯 체크 */}
                    <div className={'videoWrap videoProgress'+Progress}  style={{...MergeStyle.object,overflow:'hidden'}} > 
                    <div className='videoHover'>
                         {Copyright?
                         <div className='copyrightMode'>
                            저작권 보호모드
                         </div>
                         :null}
                    </div>
                    {getVideo()}
                    </div>
                </div>
                </Fragment>
        
            );
        /* 방송화면에서 보이는 위젯의 모습 */
        case 'broadcast':

            if(Copyright){
                MergeStyle.layout['filter'] = 'blur(20px)'
                MergeStyle.layout['overflow'] = 'hidden'
            }

            return (
                <Fragment>
                <style jsx>{`${WidgetStyle+iframeStyle}`}</style>
                <div style={MergeStyle.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                {/* <div>
                    {getVideo()}
                    </div> */}
  
                    <div className={'videoWrap videoProgress'+Progress}  style={{...MergeStyle.object,overflow:'hidden'}} > 
                    {getVideo()}
                    </div>
                </div>
                </Fragment>
        
            );
        /* 미리보기 화면에서 보이는 위젯의 모습 */
        case 'view':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                    <div>
                    {getPreview()}
                    </div>
                </div>
                </Fragment>
        
            );
        default:
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={Style.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                    {/* {getYoutube()} */}
                </div>
                </Fragment>
            );

    }


    
}


export default VideoV2;

const WidgetStyle = `
.widgetObject .YTOBJ{
    display :flex;
    object-fit: cover;
}

.widgetObject.Video {

}

.widgetObject.Video .videoWrap .videoHover{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.copyrightMode{
    width: 120px;
    height: 30px;
    margin : 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius : 5px;
    background-color: red;
    font-family: '프리텐다드SemiBold';
    color : #fff;
}

.widgetObject.Video .youtubeWrap{
    display: flex;
    position: relative; 
    width: 100%;
    height: 100%;
}



.widgetObject.Video .youtubeWrap .youtubePreviewImage{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.widgetObject.Video .youtubeWrap .youtubeVideo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.widgetObject.Video .youtubeWrap .youtubeInfo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.widgetObject.Video .youtubeWrap .youtubeVideo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}


.broadcast.widgetObject.Video .youtubeWrap .youtubePreviewImage{
    transition: all 1s;
}
.broadcast.widgetObject.Video .youtubeWrap .youtubeVideo {
    transition: all 1s;
}
    
.broadcast.widgetObject.Video .youtubeWrap .youtubeVideo .youtubeVideoIframe{
    transition: all 1s;
}

`;

