////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , Profiler, createContext} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import _ from 'lodash';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/edit.scss"


// 컴포넌트 연결

// 서비스 연결
import * as deviceService from 'service/device/deviceService' ; 
import * as Utility from'service/other/Utility.js' ; 
import * as viewAPI from 'service/api/view' ; 
import * as spaceAPI from 'service/api/space' ; 
import * as pageAPI from 'service/api/page' ; 
import { token } from 'service/event/Account'; 
import ShareContext from 'service/context/ShareContext';
import { widget } from 'service/value/Model';
import { object } from 'service/event/template';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

class ShareProvider extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        sourceList : {video : [], screen : []},
        screenList : [],
        streamList : {},
        profileList : {},
        apeendStream : this.apeendStream,
        removeStream : this.removeStream,
        screenShare : this.screenShare,
        setPreview : this.setPreview,
      };
      this.spaceSocket = spaceAPI.start();
      
      this.deviceID = deviceService.getID()

    }


    async componentDidMount()  {

        console.log('RTC 프로바이더 작동',this.props)

        this.spaceSocket.on('space', (token) => {
          console.log(`📡 소켓 : View 소켓이 연결되었습니다, 연결된 뷰 : ${this.props.uuid} `);
          // this.joinView(this.props.uuid)
      });

      const videoDevices =  await this.deviceCheck()

      for (let index = 0; index < videoDevices.length; index++) {
        this.apeendStream(videoDevices[index].deviceId)
      }
      
      this.spaceSocket.emit('join', {
          uuid : this.props.spaceID,
          deviceID : this.deviceID,
          // accountToken : token('account'),  
      });

    }

    


    componentDidUpdate(prevProps, prevState) {


      console.log('체크', this.props)


    }

    ////////////////////////////////////////////////////////
    // 소켓 연결 부분
    ////////////////////////////////////////////////////////


    initProvider () {

    }

    deviceCheck = async () => {
        try {
          // 디바이스 정보를 가지고 옵니다.
          const allDevices = await navigator.mediaDevices.enumerateDevices();

          // 비디오 디바이스만 필터링 합니다.
          const videoDevices = allDevices.filter(device => device.kind === 'videoinput');

          // 비디오 디바이스를 가지고 옵니다.
          this.setState({
            sourceList : {screen : [...this.state.sourceList.screen],video : videoDevices}
          },()=>this.shareListUpdate())

          return videoDevices
        } catch (error) {
          console.error('디바이스를 가지고 오지 못했습니다. : ', error);
        }
    }
    
    shareListUpdate = async () => {

      const videoList = this.state.sourceList.video.map((device) => ({
        shareID : device.deviceId,
        shareLabel : device.label,
        shareType : device.kind,
        shareState : 'online',
      }))

      const screenList = this.state.sourceList.screen.map((screen) => ({
        shareID : screen.screenId,
        shareLabel : '화면캡쳐',
        shareType : 'screen',
        shareState : 'online',
      }))

      const shareList = [...videoList,...screenList]

      this.spaceSocket.emit('setShareList', {
          uuid : this.props.spaceID,
          shareList : shareList,
          deviceID : this.deviceID,
      });


    }



    screenShare =  async (screenid) => {
      try {
        const Stream = await navigator.mediaDevices.getDisplayMedia({
          video: { 
            cursor: 'never',
            width: { ideal: 1920 }, // 원하는 해상도 너비
            height: { ideal: 1080 }, // 원하는 해상도 높이
            frameRate: { ideal: 30 } // 선택 사항: 프레임 레이트 설정
          },
          audio: true // 선택 사항
        });
          // 스트림이 중지되었을 때 감지하는 이벤트 핸들러
          Stream.getVideoTracks()[0].onended = () => {
            console.log('사용자가 화면 공유를 중지했습니다.');
            alert('화면 공유가 중지되었습니다.');
        };

        // 또는 스트림 전체가 비활성화되었을 때 감지
        Stream.oninactive = () => {
            console.log('화면 공유 스트림이 중지되었습니다.');
            alert('화면 공유가 중지되었습니다.');
        };

        this.setState((prevState) => ({
          streamList: { ...this.state.streamList, [screenid]: Stream }
        }));
        
        this.setState((prevState) => ({
          sourceList : {  video : [...this.state.sourceList.video] , screen : [...this.state.sourceList.screen, { screenid: screenid }]}
        }));

      } catch (error) {
        console.error('Error accessing the selected camera: ', error);
      }
    }


    apeendStream = async (deviceId) => {

      console.log('deviceId')
      try {
        const Stream = await navigator.mediaDevices.getUserMedia({
          video: { 
            deviceId: { exact: deviceId },
            width: { exact: 640 },
            height: { exact: 480 },
         },

        });
        this.setState((prevState) => ({
          streamList: { ...prevState, [deviceId]: Stream }
        }));
      } catch (error) {
        console.error('Error accessing the selected camera: ', error);
      }
    }

    removeStream = (shareId) => {
      if (this.state.streamList[shareId]) {
        this.state.streamList[shareId].getTracks().forEach((track) => {
          track.stop(); // 모든 트랙 중지
        });

        this.setState((prevState) => {
          const { [shareId]: _, ...newStreamList } = prevState.streamList; // shareId 키 삭제 후 나머지 복사
          return { streamList: newStreamList };
        });
        

        const filteredScreen = this.state.sourceList.screen.filter(item => item.screenid !== shareId);

        const filteredDevice = this.state.sourceList.video.filter(item => item.deviceId !== shareId);

        this.setState({
          sourceList : {video : filteredDevice, screen : filteredScreen}, 
        })

        
      }
    }

    setPreview = (streamID,previewImage) =>{

      this.setState({
        profileList : {...this.state.profileList,[streamID]:previewImage},
      },()=>{
        this.spaceSocket.emit('setShareProfile', {
          uuid : this.props.spaceID,
          profile : this.state.profileList,
          deviceID : this.deviceID,
      });

      })
    }
  
    componentWillUnmount() {
        // 방을 나갑니다.
        console.log('소켓을 종료합니다.')
    }
  
    render() {

      return (
        <ShareContext.Provider value={this.state}> 
            {this.props.children}
        </ShareContext.Provider>
      );
    }
  }

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////

    // export default Login;
    export default ShareProvider;