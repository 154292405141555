////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import * as Editor from 'service/edit/EditService' ; 
import { Route, Link, useParams} from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoW from 'assets/logoW.svg';
import PromotionVideo from 'assets/Promotion/overlapPromotion1.mp4';

// 컴포넌트 연결
import HeroSection from 'components/Promotion/HeroSection'; // 오버랩 인트로 색션
import OverviewSection from 'components/Promotion/OverviewSection'; // 중요한 내용 색션
import BottomSection from 'components/Promotion/BottomSection'; // 바텀 부분 색션
import FeatureSection from 'components/Promotion/FeatureSection'; // 오버랩의 특징 설명 색션

import AccountBanner from 'components/Promotion/AccountBanner'; // 어카운트 관련 배너
// SASS&CSS 연결
import "sass/frontPage.scss"

// 서비스 연결
import * as apiNotice from 'service/api/notice' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function NoticeDetailPage () {
    const { type, id } = useParams(); // URL 파라미터에서 'id'를 가져옵니다.
    const [PostDetail, setPostDetail] = useState({}); 

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////


    useEffect(() => {
        const getPostDetail =  async () => {
            const Post =  await apiNotice.get(id);
            console.log('Post',Post)
            setPostDetail(Post)
        }
        getPostDetail();
        return () => {
        };
    },[]);


    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
      <div id = "NoticePage" className='' >
   
                  
       
        <div id = "NoticePageHeader" >
            <Link  to={`/post/${type}`}>
            <div className='back'>
                {Editor.icon({icon:'arrow_back',size:25,lineHeight:30,color:'#000'})}
                목록으로 이동
            </div>
            </Link>

        <h2>{PostDetail?.title}</h2>
            </div>
        <div id = "NoticeContent" >
            <div>
            <p>{PostDetail?.content?.[0]?.content}</p>
            </div>

        </div>

      </div>
      </Fragment>
    );

}

export default NoticeDetailPage;



const PageStyle = `

#NoticePage{
    display :flex;
    flex-direction: column;
    width : 100%;
    min-height : 100vh;
    color : #fff;
    background-color : #202020;
    font-family: '프리텐다드ExtraBold';
    align-items: center;

}

#NoticePageHeader {
    max-width : 1200px;
    width : 100%;
    height: 200px;
    margin-top : 50px;
    background-color : #fff;
    color : #202020;
    display :flex;
    flex-direction: column;
    justify-content: space-between;
}

#NoticePageHeader h2 {
    font-size : 40px;
    margin-left : 30px;
}

#NoticePage a {
    text-decoration: none;
}

#NoticePageHeader .back{
    display: flex;
    line-height: 30px;
    margin-left : 30px;
    margin-top : 30px;

}   

#NoticeContent{
    max-width : 1200px;
    width : 100%;
    margin-top : 20px;
    display : flex;
    line-height: 1.5;
}
#PromotionContent .NoticeContent{
    max-width : 1200px;
    width : 100%;
    height: 100px;
    margin : 20px 0px;
    display :flex;
    border-bottom : solid 1px #fff;
    justify-content: space-between;
    color : #C0C0C0;
}

#PromotionContent .NoticeContent:hover{
    color : #fff;
}

#PromotionContent .NoticeTitle{
    flex : 3;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 30px;
    padding : 0px 10px;
    box-sizing: border-box;
}
#PromotionContent .NoticeDate{
    flex : 1;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 20px;
    padding : 0px 10px;
    align-items: end;
    box-sizing: border-box;
}

#PromotionPage #PromotionContent{
    position: relative;
    display :flex;
    flex-direction: column;
    width : 100%;

}




`;