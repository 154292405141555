

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import * as Editor from 'service/edit/EditService' ; 
import { Route, Link, useParams} from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoAnimate from 'assets/logoAnimate.svg';

// 컴포넌트 연결

// SASS&CSS 연결
import "sass/frontPage.scss"

// 서비스 연결
import * as notice from 'service/api/notice' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function NoticePage () {
    const { type} = useParams(); // URL 파라미터에서 'id'를 가져옵니다.
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [SelectPost, setSelectPost] = useState('notice'); 

    const [PostList, setPostList] = useState(undefined); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
    const getPost =  async () => {
        const Posts =  await notice.getList(type)
        setPostList(Posts)
    }

    getPost();
    return () => {
    };
    },[SelectPost]);

    const getList = () => {
        console.log('PostList',PostList)
        if (PostList == undefined) {
            return(
                <div className='VoidPost'>
                    <img src={logoAnimate} width={200} height={200}  alt="Logo Animation" />
                </div>
            )
        } else if (PostList.length == 0){
            return(
                <div className='VoidPost'>
                    게시글이 없습니다.
                </div>
            )
        } else {
            const List = PostList.map((post) => (
                <Link  to={`/post/${type}/${post?.id}`}>
                    <div className='NoticeContent'>
                        <h3 className='NoticeTitle'>
                        {post?.title}
                        </h3>
                        <div className='NoticeDate'>
                        {timeAgo(post?.created_at)}
                        </div>
                    </div>
                </Link>

            ))
            return List;
        }
    }


    const getPostTitle = () =>{
        switch (type) {
            case 'notice':
                return '공지사항'
            case 'update':
                return '업데이트'
        
            default:
                break;
        }
    }



    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
    
      <div id = "NoticePage" className='' >
       

        <div id = "NoticePageHeader" >
            <Link  to={`/`}>
            <div className='back'>
                {Editor.icon({icon:'arrow_back',size:25,lineHeight:30,color:'#000'})}
                첫 페이지로 이동
            </div>
            </Link>
            <h2>{getPostTitle()}</h2>
        </div>
        <div id = "PromotionContent" >
            {getList()}
            
        </div>

      </div>
      </Fragment>
    );

}

export default NoticePage;



const PageStyle = `

#NoticePage{
    display :flex;
    flex-direction: column;
    width : 100%;
    min-height : 100vh;
    color : #fff;
    background-color : #202020;
    font-family: '프리텐다드ExtraBold';
    align-items: center;

}

#NoticePageHeader {
    max-width : 1200px;
    width : 100%;
    height: 200px;
    margin-top : 50px;
    background-color : #fff;
    color : #202020;
    display :flex;
    flex-direction: column;
    justify-content: space-between;
}

#NoticePageHeader h2 {
    font-size : 40px;
    margin-left : 30px;
}

#NoticePage a {
    text-decoration: none;
}

#NoticePageHeader .back{
    display: flex;
    line-height: 30px;
    margin-left : 30px;
    margin-top : 30px;

}   



#PromotionContent{
    max-width : 1200px;
    width : 100%;
    min-height: 600px;
}
#PromotionContent .NoticeContent{
    max-width : 1200px;
    width : 100%;
    height: 100px;
    margin : 20px 0px;
    display :flex;
    border-bottom : solid 1px #fff;
    justify-content: space-between;
    color : #C0C0C0;
    text-decoration: none;
}

#PromotionContent .NoticeContent:hover{
    color : #fff;
}

#PromotionContent .NoticeTitle{
    flex : 3;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 30px;
    padding : 0px 10px;
    box-sizing: border-box;
}
#PromotionContent .NoticeDate{
    flex : 1;
    height: 80px;
    display :flex;
    flex-direction: column;
    justify-content: end;
    font-size : 20px;
    padding : 0px 10px;
    align-items: end;
    box-sizing: border-box;
}

#PromotionPage #PromotionContent{
    position: relative;
    display :flex;
    flex-direction: column;
    width : 100%;

}

.VoidPost{
    display :flex;
    justify-content: center;
    align-items: center;
    width : 100%;
    height: 400px;
    margin-top :10px;
    font-size : 20px;

}




`;



function timeAgo(dateString) {
    const then = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - then) / 1000);
  
    if (diffInSeconds < 180) {
      return `지금`;
    }
    if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}분 전`;
    }
    if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)}시간 전`;
    }
    return `${Math.floor(diffInSeconds / 86400)}일 전`;
  }