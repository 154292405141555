// 리엑트 연결

// 서비스 연결
import * as Utility from 'service/other/Utility';
import * as Model from 'service/value/Model' ;  
////////////////////////////////////////////////////////
// 디바이스 고유번호를 관리합니다.
////////////////////////////////////////////////////////


export function getID() {
    let uuid = localStorage.getItem('deviceID')??null;

    if(!uuid){
        uuid = crypto.randomUUID ? crypto.randomUUID() : generateUUID(); 
        localStorage.setItem('deviceID', uuid);
    }

    return uuid
}

function generateUUID() { // Public Domain/MIT
    let d = new Date().getTime();//Timestamp
    let d2 = (performance && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){ //Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else { //Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c==='x' ? r : (r&0x3|0x8)).toString(16);
    });
}
