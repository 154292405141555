////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { withRouter } from 'react-router-dom';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import "sass/main.scss"
import "sass/lobby.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';
import editSetting from 'assets/edit/EditScreenSetting.png';


// 컴포넌트 연결
// import Text from './template/Text'; // 메뉴 헤더
import TutorialOBSsetting from "./TutorialOBSsetting"
import EditOverlap from "pages/lobby/space/EditOverlap"
import SpaceDashboard from "pages/lobby/space/SpaceDashboard"
import SpaceOverlap from "pages/lobby/space/SpaceOverlap"
import OverlapStore from "pages/lobby/space/OverlapStore"
import SpaceSet from "pages/lobby/space/SpaceSet"
import SpaceShare from "pages/lobby/space/SpaceShare"
import SpaceView from "pages/lobby/space/SpaceView"
import SpaceLibrary from "pages/lobby/space/SpaceLibrary"
import SpaceService from "pages/lobby/space/SpaceService"
import SpaceAdmin from "pages/lobby/space/SpaceAdmin"


import Account from "./Account"
// import Guide from "./Guide"
import Guide from "pages/lobby/guide/LobbyGuide"
import SpaceDropDown from "components/Lobby/space/SpaceDropDown"

// 서비스 연결
import * as apiAccount from 'service/api/apiAccount';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class LobbyPage extends Component {
    
    state = {
        account : '',
        lobby : 'Project',
        lobbyState : null,
        space : null
    }

    noMakeAlert = () => {
        alert("준비중인 기능입니다.");
    } 

    logout = () => {
        sessionStorage.removeItem("JWT");
        window.location.href='/';
    }


    setSpace = (space) => {
        this.setState({
            space
        })
    }

    blockNavigation = (location, action) => {
        if (action === 'POP') {
          if (!window.confirm('정말 이 페이지를 떠나시겠습니까?')) {
            return false;
          }
        }
      }

    async componentWillMount() {
        // window.onbeforeunload = () => true; // 사용자가 페이지를 벗어날 때 경고 표시
        // 계정 정보를 가저옵니다. 
        const AccountState = await apiAccount.getAccount();

        console.log('AccountState',AccountState)

        const userProfile = await apiAccount.getProfile(this.props);
    // 여기 문제......!!!! undefined 나오는 이유가 뭔지 체크 
        console.log('userProfile',userProfile)

        if(userProfile == undefined) {
            // 만약 유저 프로필이 없는경우 유저 프로필 생성 페이지로 이동합니다.
            this.props.history.replace('/initprofile');
        }

        let account = {
            nick : userProfile?.['profile_nick']??null, 
            image : userProfile?.['profile_image']??null,
            role : AccountState?.role??null,  
        };
        this.props.setAccountDispatch(account);
    }



    // componentDidMount() {
    //     // history 객체의 변경을 감지하기 위한 리스너 등록
    //     this.unlisten = this.props.history.listen((location, action) => {
    //       if (action === 'POP') {
    //         // 뒤로 가기 버튼이 눌렸을 때 원하는 액션 실행
    //         console.log('Back button pressed');
    //         // 여기에 원하는 함수나 로직을 추가할 수 있습니다.
    //         alert('으악')
    //         this.handleBackButton();
    //       }
    //     });
    //   }
    
      componentWillUnmount() {
        // 컴포넌트 언마운트 시 리스너 제거
        // if (this.unlisten) {
        //   this.unlisten();
        // }
        // window.onbeforeunload = null; // 컴포넌트 언마운트 시 경고 제거
      }

    componentDidUpdate(prevProps){

        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-R99737TJCR", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
        }
    }

    lobbyChange=(lobby,lobbyState=null)=>{
        // this.setState({
        //     lobby,lobbyState
        // })

        this.props.history.push({
        pathname: '/lobby',
        state: { lobby: lobby, lobbyState: lobbyState },
        });
    }

    lobbyRoutes = ({target,option=null,mode='push'}) => {
        console.log('문제',target)
        switch (mode) {
            case 'push':
                this.props.history.push({
                pathname: '/lobby',
                state: { lobby: target, lobbyState: option },
                });
                break;
            case 'replace':
                this.props.history.replace({
                pathname: '/lobby',
                state: { lobby: target, lobbyState: option },
                });
                break;
        
            default:
                break;
        }
    }

    guide = () => {

        return(
            null
            // <div id="LobbyGuide">

            // </div>
        )

    }

    userRole = (role) => {

        switch (role) {
            case 99:
                return <div className="userRole roleAdmin"> <p>최종 관리자</p> </div>
            case 90:
                return <div className="userRole roleAdmin"> <p>관리자</p> </div>
            case 71:
                return <div className="userRole roleTest"> <p>테스터</p> </div>
            case 1:
                return <div className="userRole roleUser"> <p>얼리엑세스 테스터</p> </div>
        
            default:
                break;
        }

            return(
                null
                // <div id="LobbyGuide">

                // </div>
            )

    }

    lobbyContent=()=>{
        const { lobby, lobbyState } = this.props.location.state || {lobby:'Project'};
        if(!this.state.space){
            return (null)
        }

        switch (lobby) {

            case 'Dashboard':
                return(<SpaceDashboard account={this.state.account} space={this.state.space}/>) 
            case 'EditOverlap':
                return(<EditOverlap account={this.state.account}/>)
            case 'Project':
                return(<SpaceOverlap space={this.state.space} lobbyRoutes={this.lobbyRoutes}/>) 
            case 'Channel':
                return(<SpaceView space={this.state.space} lobbyRoutes={this.lobbyRoutes}/>) 
            case 'Share':
                return(<SpaceShare space={this.state.space}/>) 
            case 'Set': 
                return(<SpaceSet space={this.state.space}/>) 
            case 'Store':  // 오버랩 템플릿등 다양한 요소를 거래 할 수 있습니다.
                return(<OverlapStore space={this.state.space} lobbyRoutes={this.lobbyRoutes}/>) 
            case 'Library': // 사용자가 오버렙에 업로드한 파일을 관리합니다.
                return(<SpaceLibrary space={this.state.space} lobbyRoutes={this.lobbyRoutes}/>) 
            case 'Service':
                return(<SpaceService space={this.state.space} lobbyRoutes={this.lobbyRoutes}/>) 
            case 'Account':
                return(<Account account={this.state.account}/>) 
                break;
            case 'MobileApp':
                // return() 
                break;    
            case 'Variable':
                // return() 
                break;    
            case 'Link':
                return( <TutorialOBSsetting/>)  
                break;  
            case 'Guide':
                return( <Guide subAddress={lobbyState} lobbyRoutes={this.lobbyRoutes}/>) 
                break;
            case 'Bot':
                // return()  
                break;
            case 'Admin':
                return(<SpaceAdmin/>) 
                break;

                
            default:
                return(<div> lobby : {lobby}, lobbyState: {lobbyState}</div>)
                break;
        }
    }


    
   


    render() { 
        const { lobby, lobbyState } = this.props.location.state || {lobby:'Project'};
        return (
            <div id = "LobbyPage" >
                <header>
                    <div className="flexbox">
                        <div id="logo"  onClick={()=>lobby == 'Project'?this.lobbyRoutes({target:'Project',mode:'replace'}):this.lobbyRoutes({target:'Project'})} >
                            <div id="MainPageLogo">
                                <img className="mainLogo" src={logoW}/>
                            </div>
                               <h6 id="SiteName">OVERLAP</h6>
                        </div>
                  
                    </div>
 
                    <div className="flexbox">
                       {/* 여기는 알람이 들어가야 합니다. */}
                    </div>

                </header>
                <main>
                    {/* 메인 */}
                    <div id="sidebar">
                        <div id="upperNav"> 
                            <div id="accountNav">
                                {/* 프로필 박스 */}
                                <div id="accountBox">
                                    {/* <h3>{this.state.account}</h3> */}
                                    <div className="myProfile">
                                        <div className="profileTop">  
                                            <div className="onair"> 
                                                {/* <svg width="20px" height="20px">
                                                    <circle cx="10" cy="10" r="8" fill="red" stroke="#fff" stroke-width="3"/>
                                                </svg> */}
                                            </div>
                                            <img className="profileImg" src={this.props?.account?.image??logoW}/>
                                            <div className="profileSet"> 
                                                <img className="widgetListImg" src={editSetting}/>
                                            </div>
                                        </div>
                                        <div className="profileMid">  
                                            <div className="profileData">
                                                <h3  className="profileName">
                                                {this.props?.account?.nick}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className="profileBottom">  
                                            {this.userRole(this.props?.account?.role)}
                                            {/* <div className="userPoint"> 
                                                <span>
                                                
                                                </span>
                                                <span>
                                                500P
                                                </span>
                                            </div> */}
                                        </div>                                 
                                    </div>
                                </div>
                            </div>
                            <SpaceDropDown space={this.state.space} setSpace={this.setSpace}/>   
                            {this.state.space == undefined ? 
                                <ul id="SpaceNav">
                                
                                </ul>
                            :
                                <ul id="SpaceNav">
                                    {/* <li onClick={()=>this.lobbyChange('Dashboard')} className={this.state.lobby == 'Dashboard'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 대시보드 </li> */}

                                    <li onClick={()=>lobby == 'Project'?this.lobbyRoutes({target:'Project',mode:'replace'}):this.lobbyRoutes({target:'Project'})} className={lobby == 'Project'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 프로젝트 </li>
                                    <li onClick={()=>lobby == 'Channel'?this.lobbyRoutes({target:'Channel',mode:'replace'}):this.lobbyRoutes({target:'Channel'})} className={lobby == 'Channel'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 채널 </li>
                                    <li onClick={()=>lobby == 'Library'?this.lobbyRoutes({target:'Library',mode:'replace'}):this.lobbyRoutes({target:'Library'})} className={lobby == 'Library'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 라이브러리 </li>
                                    <li onClick={()=>lobby == 'Store'?this.lobbyRoutes({target:'Store',mode:'replace'}):this.lobbyRoutes({target:'Store'})} className={lobby == 'Store'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 스토어 </li>
                                    {/* <li onClick={()=>lobby == 'Service'?this.lobbyRoutes({target:'Service',mode:'replace'}):this.lobbyRoutes({target:'Service'})} className={lobby == 'Service'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 연결 </li> */}
                                    
                                    {/* <li onClick={()=>this.lobbyChange('Set')} className={this.state.lobby == 'Set'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 스페이스 </li> */}
                                    <li onClick={()=>lobby == 'Guide'?this.lobbyRoutes({target:'Guide',mode:'replace'}):this.lobbyRoutes({target:'Guide'})} className={lobby == 'Guide'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 가이드 </li>
                                    <li onClick={()=>window.open(`/share?uuid=${this.state?.space?.uuid}`, '_blank')} className={lobby == 'Share'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 영상 연결 </li>

                                    {/* <li onClick={()=>this.lobbyChange('EditOverlap')} className={this.state.lobby == 'EditOverlap'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 오버랩 편집 </li> */}
                                    {/* <li onClick={()=>this.lobbyChange('Account')} className={this.state.lobby == 'Account'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 계정 설정 </li> */}
                                    {/* <li onClick={()=>this.lobbyChange('MobileApp')} className={this.state.lobby == 'MobileApp'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 모바일 설정 </li>
                                    <li onClick={()=>this.lobbyChange('Variable')} className={this.state.lobby == 'Variable'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 변수 설정 </li>
                                    */}
                                    {/* <li onClick={()=>this.lobbyChange('Link')} className={this.state.lobby == 'Link'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> OBS 연동 </li> */}
                                    {/* <li onClick={()=>this.lobbyChange('Bot')} className={this.state.lobby == 'Bot'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 채팅관리 </li> */}
                                    {this.props?.account?.role == 90 || this.props?.account?.role == 99 ?
                                    <li onClick={()=>lobby == 'Admin'?this.lobbyRoutes({target:'Admin',mode:'replace'}):this.lobbyRoutes({target:'Admin'})} className={lobby == 'Admin'?"lobbyNavSelectList lobbyNavList":"lobbyNavList" }> <span/> 관리자 도구 </li>
                                    : null }
                                </ul>
                            }
                        </div>
                        <div id="lowerNav"> 
                            <ul id="CommonNav">
                            <li onClick={()=>this.logout()} className="lobbyNavList"> <span/> 로그아웃 </li>
                            </ul>  
                        </div>
                
                    </div>
                    <div id="content">
                        {this.lobbyContent()}
                    </div>
               
                    {/* 메인 */}
                </main>
                <div>
                    
                </div>
                
                {this.guide()}
            </div>
        );
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    account : state.account,
    passToken : state.passToken,
})

function mapDispatchToProps(dispatch){
    return {
      setAccountDispatch : (account={},) => dispatch({type:"setAccount",nick : account['nick']??null,role : account['role']??null,image : account['image']??null,message : account['message']??null,mode : account['mode']??null,socialAccount : account['socialAccount']??null }),
    }
}

  // export default Login;
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LobbyPage));
  