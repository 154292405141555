////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoAnimate from 'assets/logoAnimate.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as admin from 'service/api/admin' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AdminModuleUserDashboard({createPost,readPost}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state


    const [totalUser, setTotalUser] = useState(null); 

    const [weekUser, setWeekUser] = useState(null); 

    const [monthUser, setMonthUser] = useState(null); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {

    //     return () => {
    //     };
    //   },[]);

    console.log('monthUser',monthUser);


      useEffect(() => {
        const initTab =  async () => {
            const TotalCount =  await admin.getUserTotalCount()
            if(typeof TotalCount?.data === 'number'){
                setTotalUser(TotalCount?.data)
            }else{
                setTotalUser(false)
            }

            const WeekCount =  await admin.getRecentAccessedCount({dateRange:7})

            console.log('WeekCount',WeekCount.data)
            if(WeekCount?.data?.length&&typeof WeekCount?.data?.length === 'number'){
                setWeekUser(WeekCount?.data?.length)
            }else{
                setWeekUser(false)
            }

            const MonthCount =  await admin.getRecentAccessedCount({dateRange:30})
            console.log('MonthCount',MonthCount.data.length)
            if(MonthCount?.data?.length&&typeof MonthCount?.data?.length === 'number'){
                setMonthUser(MonthCount?.data?.length)
            }else{
                setMonthUser(false)
            }

        }
        initTab();
   
      },[]);

    return (
        <div className='AdminModuleUser AdminModule' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='AdminUserField'>

            <div className='totalCountPanel'>
                <div className='totalCount'>
                {totalUser==false?'-':totalUser} 개
                </div>
                <div className='totalInfo'>
                생성된 계정
                </div>
      
            </div>

            <div className='monthCountPanel'>
                <div className='monthCount'>
                {monthUser==false?'-':monthUser} 명
                </div>
                <div className='monthInfo'>
                최근 접속자
                </div>
      
            </div>
        </div>
    </div>
    );
  }

//   <div className='countInfo'>
//   일주일 내 접속 숫자 {weekUser}
//   </div>
//   <div className='countInfo'>
//   한달 내 접속 숫자 {monthUser}
//   </div>

  export default AdminModuleUserDashboard;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminModule {

    }

`;

const ContainerStyle = `
   .AdminModuleUser{
        display: flex;
        width : 100%;
    }


    .AdminUserField{
        display: flex;
        width : 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top : 20px;
        gap: 20px; /* 전체에 10공간을 준다 */
    }

    .totalCountPanel{
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-around;
        width : 250px;
        height : 50px;
        padding : 25px;
        background: #f12711;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #f5af19, #f12711);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #f5af19, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        border-radius : 5px;


    }

    .totalCountPanel .totalCount{
        font-size : 30px;
    }

    .totalCountPanel .totalInfo{
        color : #e0e0e0;
    }

    .monthCountPanel{
        display: flex;
        flex-direction: column;
        align-items: end;
        justify-content: space-around;
        width : 250px;
        height : 50px;
        padding : 25px;
        background: #d53369;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #cbad6d, #d53369);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #cbad6d, #d53369); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        border-radius : 5px;
    }

    .monthCountPanel .monthCount{
        font-size : 30px;
    }

    .monthCountPanel .monthInfo{
        color : #e0e0e0;
    }

    .countInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height : 50px;
        font-size : 20px;
    }
    
        
    
`;



function timeAgo(dateString) {
    const then = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - then) / 1000);
  
    if (diffInSeconds < 180) {
      return `지금`;
    }
    if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}분 전`;
    }
    if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)}시간 전`;
    }
    return `${Math.floor(diffInSeconds / 86400)}일 전`;
  }