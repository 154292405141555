////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as apiNotice from 'service/api/notice' ; 

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AdminModulePost({category,listPost,postId}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [SelectPost, setSelectPost] = useState('notice'); 

    const [PostTitle, setPostTitle] = useState(''); 


    const [PostContent, setPostContent] = useState([]); 
    const [PostCategory, setPostCategory] = useState(category); 
    const [PostID, setPostID] = useState(postId); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {

        const getPostDetail =  async () => {
            const Post =  await apiNotice.get(postId);
            console.log('Post 222',Post,postId)

            if(Post){
                setPostContent(Post?.content)
                setPostTitle(Post?.title)
                setPostCategory(Post?.category)
                setPostID(Post?.id)
            }



        }
        console.log('Post 222')
        if(postId!=null){
            getPostDetail();
        }

        return () => {
        };
      },[]);





    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     setTimeout(function() {
    //         getFileList()
    //       }, 1000);
    //     // Cleanup function (optional)
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[props.mode]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    
    const test = async () => {
        const t = await apiNotice.del(PostID);
        console.log('t',t)
        
        listPost()
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////



    return (
        <div className='AdminModulePost AdminModule' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='PostField'>

        <nav className='PostNav'>

            <button onClick={()=>{listPost()}}>
                뒤로가기
            </button>
            
            <div>
            {PostID!=null ?
            <button onClick={()=>{test()}}>
                삭제하기
            </button>
            :null}
            <button onClick={()=>{PostID!=null ?apiNotice.update(PostID, PostTitle, PostContent, PostCategory):apiNotice.create(PostTitle, PostContent, PostCategory);listPost()}}>
                저장하기
            </button>
            </div>


            

        </nav>
        <div className='PostContent'>
            <div className='CatField ContentField'>
                <div className='FieldInfo'>
                    카테고리
                </div>
                <input className='FieldForm catForm' value={PostCategory} readOnly/>
            </div>

            <div className='TitleField ContentField'>
                <div className='FieldInfo'>
                    제목
                </div>
                <input className='FieldForm titleForm' value={PostTitle} onChange={(e)=>{setPostTitle(e.target.value)}}/>
            </div>

            <div className='TextField ContentField'>
                <div className='FieldInfo'>
                    텍스트
                </div>
                <textarea className='FieldForm textForm' value={PostContent[0]?.content??''} onChange={(e)=>{setPostContent([{type:'text',content:e.target.value}])}}/>
            </div>
        </div>

        <textarea value={JSON.stringify(PostTitle)} readOnly />
        <textarea value={JSON.stringify(PostContent)} readOnly />

  
        </div>
    </div>
    );
  }

  export default AdminModulePost;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminModule {

    }

`;

const ContainerStyle = `
   .AdminModulePost{
        display: flex;
        margin : 0px 20px;
        width : 100%;
    }


    .PostField{
        display: flex;
        width : 100%;
        flex-direction: column;
    }

    .TabHeader{
    }
    

    .TabTitle{
        display flex;
        width : 100%;
        height : 50px;
        font-size : 25px;
    }
    
    .PostNav{
        display : flex;
        flex-direction: row;
        width : 100%;
        height : 50px;
        margin-bottom : 20px;
        justify-content: space-between;
        color : #ccc;
    }

    .PostNav button{
        width : 100px;
        height : 40px;
        color : #ccc;
        border-radius : 10px;
        background-color : #222;
    }

    .ContentField{
        display : flex;
        width : 100%;
        min-height : 50px;
        margin-bottom : 20px;
    }

    .FieldInfo{
        display : flex;
        width : 100px;
    }
    .FieldForm{
        display : flex;
        width : calc( 100% - 100px );
        border : solid #999 1px;
        color : #ccc;
    }

    .textForm{
        resize: none;
        min-height : 150px;
        border-radius : 10px;
        background : #333;
        font-size : 18px;
        line-height : 20px;
        font-family: '프리텐다드SemiBold';
        padding : 5px; 
        box-size : border-box;
    }

    .titleForm{
        border-radius : 10px;
        background : #333;
        font-size : 35px;
        line-height : 50px;
        font-family: '프리텐다드SemiBold';
        padding : 5px; 
        box-size : border-box;
    }

    .catForm{
        resize: none;
        width : 100px;
        text-align : center;
        border-radius : 10px;
        background : #444;
        font-size : 22px;
        line-height : 20px;
        font-family: '프리텐다드SemiBold';
        padding : 5px; 
        box-size : border-box;
    }

`;

