
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account' ; 

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
/** create
 * @param title 게시글 제목을 적습니다.
 * @param content 컨텐츠 내용을 적습니다.
 * @param category 컨텐츠 카테고리
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function getUserList(offset,limit) {

    let response =  await Rest.request('/admin/user/list','get',{offset,limit},token('account'));
    if(response?.status == 200){
        return response?.data;
    }
    return false;
}

export async function getUser(userID) {

    let response =  await Rest.request('/admin/user','get',{userID},token('account'));
    if(response?.status == 200){
        return response?.data;
    }
    return false;
}

export async function getUserTotalCount() {

    let response =  await Rest.request('/admin/user/total','get',{},token('account'));
    if(response?.status == 200){
        return response?.data;
    }
    return false;
}


export async function getRecentAccessedCount({startDate,dateRange=7,offset=null,limit=null}) {

    // 시작일이 미정인 경우 오늘을 기준으로 합니다.
    if (!startDate){
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        const day = String(today.getDate()).padStart(2, '0'); // 일
        startDate = `${year}-${month}-${day}`;
    }
    
    // 입력받은 날짜를 Date 객체로 변환
    const date = new Date(startDate);

    // 일수 추가
    date.setDate(date.getDate() - dateRange);

    // 결과 날짜 형식을 YYYY-MM-DD로 반환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');

    const endDate = `${year}-${month}-${day}`;
    
    let response =  await Rest.request('/admin/user/recent','get',{startDate,endDate,offset,limit},token('account'));
    if(response?.status == 200){
        return response.data;
    }
    return false;
}

export async function getSpaceList(offset,limit) {
    
    let response =  await Rest.request('/admin/space/list','get',{offset,limit},token('account'));
    if(response?.status == 200){
        return response?.data;
    }
    return false;
}
