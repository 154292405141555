////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoAnimate from 'assets/logoAnimate.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as admin from 'service/api/admin' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AdminModuleUserDetail({selectID,setFieldRoute}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [account, setAccount] = useState(null); 

    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      useEffect(() => {
        const getUserList =  async () => {
            console.log('selectID',selectID);
            const List =  await admin.getUser(selectID)
            setAccount(List.data[0])
  
        }
        getUserList();
      },[]);


    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    // const getList = () => {

    //     const List = UserList.map((item,index) => (
    //         <div className='accountContainer' key={item.account_id}>
    //         <div className='accountProfile'>
    //             {/* 싱딘부 */}
    //             <div className='accountProfileTop'>
    //                 <div className='accountProfileTopLeft'>
    //                     <div className='accountProfileImage'>
    //                     {item.profile_image? 
    //                     <img src={item.profile_image}/>
    //                     :<div className='accountProfileImagePlaceholder'/>}
    //                     </div>  {/* 싱딘부 */}
    //                     <div className='accountProfileName'>
    //                         {item.profile_nick ? 
    //                         item.profile_nick
    //                         :<span className='accountUnknow'>알 수 없는 계정</span>}
                
    //                         {item.login_type == 'google' ? 
    //                         <span className='accountGoogle'>
    //                             <svg id="GoogleOAuthLogo" xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 0 24 24" width="15"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
    //                         </span>
    //                         :null}
                
    //                     </div>
    //                 </div>
    //                 <div className='accountProfileTopRight'>
    //                     <div className='accountLastAccess'>
    //                         {timeAgo(item.last_access)}
    //                     </div>
    //                 </div>
    //             </div>
    //             <div>
    //                 {/* 하단부 */}
    //             </div>
    //         </div>             
    //     </div>
    //     ))

    //     return List;
        
    // }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    return (
        <div className='AdminModuleUserDetail AdminModule' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
       
        <div className='accountInfoField'>
            <div className='accountPanel'>
                <div className='accountProfileImage'>
                    {account==null? null:account?.profile_image? <img src={account?.profile_image}/>:Editor.icon({icon:'account_circle',size:50,lineHeight:100,color:'#999'})}
                </div>
                <div className='accountProfileInfo'>
                    <div className='accountProfileNick'>
                        {account==null? null:account?.profile_nick? account?.profile_nick :<span style={{color:'#999'}}>알 수 없는 계정</span>}
                    </div>
                    <div className='accountProfileName' title={account?.account_uuid}>
                        {account?.profile_name}
                    </div>
                </div>
            </div>

            {/* <div className='accountTypePanel'>
                <div className='accountProfileImage'>
                    <img src={account?.profile_image}/>
                </div>
                <div className='accountProfileInfo'>
                    <div className='accountProfileNick'>
                        {account?.profile_nick}
                    </div>
                    <div className='accountProfileName' title={account?.account_uuid}>
                        {account?.profile_name}
                    </div>
                </div>
            </div>

            <div className='accountSpacePanel'>
                <div className='accountProfileImage'>
                    <img src={account?.profile_image}/>
                </div>
                <div className='accountProfileInfo'>
                    <div className='accountProfileNick'>
                        {account?.profile_nick}
                    </div>
                    <div className='accountProfileName' title={account?.account_uuid}>
                        {account?.profile_name}
                    </div>
                </div>
            </div> */}

        </div>
    </div>
    );
  }

  export default AdminModuleUserDetail;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminModule {

    }

`;

const ContainerStyle = `
   .AdminModuleUserDetail{
        display: flex;
        width : 100%;
    }


    .AdminModuleUserDetail .accountInfoField{
        display: flex;
        width : 100%;
        flex : 1;    
        overflow : auto;
        flex-direction: column;
    }
    
    .AdminModuleUserDetail .accountInfoField .accountPanel{
        width : 300px;
        margin-top : 20px;
        padding : 20px;
        background-color : #444;
        border-radius : 10px;
        height : 150px;  
        display :flex;
        justify-content: space-between;
    }

    .AdminModuleUserDetail .accountProfileImage{
        width : 100px;
        height : 100px;  
        background-color : #333;
        border-radius : 10px;
        overflow:hidden;
        display :flex;
        align-items: center;
        justify-content: center;
    }
    .AdminModuleUserDetail .accountProfileImage img{
        width : 100px;
        height : 100px;  
    }

    .AdminModuleUserDetail .accountProfileNick{
        width : 200px;
        height : 50px;  
        line-height : 50px;
        font-size : 30px;
        display :flex;
        justify-content: end;
    }
    .AdminModuleUserDetail .accountProfileName{
        width : 200px;
        height : 30px;  
        line-height : 30px;
        font-size : 20px;
        display :flex;
        justify-content: end;
    }
    .AdminModuleUserDetail .accountProfileUUID{
      font-size : 10px;
    }
    .AdminModuleUserDetail .accountProfile{
    
    }


    .AdminModuleUserDetail .accountProfileTop{
        display :flex;
        justify-content: space-between;
    }

    .AdminModuleUserDetail .accountProfileTopLeft{
        display : flex;
        height : 50px;
    }

    .AdminModuleUserDetail .accountProfileTopRight{
        display : flex;
        height : 50px;
    }

    .AdminModuleUserDetail .accountProfileTop .accountProfileImage{
        width : 50px;
        height : 50px;   
    }

    .AdminModuleUserDetail .accountProfileTop .accountProfileImage img{
        width : 40px;
        height : 40px;
        margin : 5px;
        border-radius : 40px;  
        object-fit: cover;
        
    }

    
`;



function timeAgo(dateString) {
    const then = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - then) / 1000);
  
    if (diffInSeconds < 180) {
      return `지금`;
    }
    if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}분 전`;
    }
    if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)}시간 전`;
    }
    return `${Math.floor(diffInSeconds / 86400)}일 전`;
  }