// 스토어생성기, 미들웨어적용 모듈 임포트
import { createStore, applyMiddleware } from 'redux';

// 여기서 부터 미들웨어/데브툴 관련 임포트
// import { composeWithDevTools } from 'redux-devtools-extension';
// import logger from 'redux-logger';
// import thunk from 'redux-thunk';

const defaultState = {
    exemple : false,
    testMode : false,
    // 계정 관련 스토어
    account  : {
        nick : null,
        role : null,
        image : null, 
        message : null,
        mode : null, 
        socialAccount : null, 
    },
    

};

const reducer = (state = defaultState, action) => {
    switch (action.type) {

        ////////////////////////////////////////////////////////
        // 계정정보 
        ////////////////////////////////////////////////////////
        case "setAccount" : 
        return {
            ...state,
            account : {
                nick : action.nick??defaultState.account.nick,
                image : action.image??defaultState.account.image, 
                message : action.message??defaultState.account.message,
                mode : action.mode??defaultState.account.mode, 
                role : action.role??defaultState.account.role, 
                socialAccount : action.socialAccount??defaultState.account.socialAccount, 
            },
        }    
        ////////////////////////////////////////////////////////
        // 테스트 모드 활성화 
        ////////////////////////////////////////////////////////
        case "setTestMode" : 
        return {
            ...state,
            testMode : action?.mode??false
        }        
        default:
            console.error("리듀스 에러",action);
            return state; 
        }
};

const store = createStore(reducer);
export default store;
