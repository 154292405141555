// -- 위젯의 정의파일 Model 입니다. 해당 파일을 widgetModel 파일에 import 시켜야 사용 가능 합니다. -- 

////////////////////////////////////////////////////////
// Video | 비디오 위젯 
// 로컬 비디오, 유튜브 등을 재생 할 수 있는 비디오 위젯 입니다.
////////////////////////////////////////////////////////

// -- 컴포넌트 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
import Camera from 'components/widget/CameraV2'; // 컴포넌트
// -- 인스턴트 에디터 불러오기 -- ('components/widget/[위젯이름]') 위치로 가저올 수 있습니다.
// import VideoController from 'components/Edit/widgetController/VideoControllerV1'; // 컴포넌트

// -- 스타일 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
import * as common from 'service/model/widget/commonStyle'; // 자주 사용되는 스타일 메이커 가저오기
import * as common_v2 from 'service/model/widget/commonStyle_V2'; // 자주 사용되는 스타일 메이커 가저오기
import * as keyframe from 'service/model/widget/keyframe'; // 자주 사용되는 스타일 메이커 가저오기

// -- 기타 함수 불러오기 -- ('service/model/widget/[스타일이름]') 위치로 가저올 수 있습니다.
// import * as [이름] from 'service/[경로]'; 


////////////////////////////////////////////////////////
// 컴포넌트 정의
// 해당 위젯 모댈과 연결할 리엑트 컴포넌트를 정의 합니다.
////////////////////////////////////////////////////////
// export const Component = [가저올 컴포넌트] ; 
export const Component = Camera; 

// export const Controller = VideoController; 

////////////////////////////////////////////////////////
// 레퍼런스 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////

export const Reference = {
  position: {x: 20, y: 20},
  size: {width: 640, height: 360},
  meta: {autoplay : true},
  style : {
    shadowValue: "0", 
    shadowColor: "#000000",
    borderRadius : 0,
    fill : "#000000",
    useFill : false,
    ...common.filterDefault,
    ...common.transformDefault,
  },
  value: {
    mediaSource: null,
    copyright: false, 
    loop: false,
    option: null
  },
  display:{
    show : true
  },
  animation:{
    mountStart:{keyFrame:null,delay:null,timing:null},
    mount:{keyFrame:null,delay:null,timing:null},
    mountEnd:{keyFrame:null,delay:null,timing:null},
    morphing:true
  },
  func : {
    getVideoType,
    getYoutubeID
  }
}

////////////////////////////////////////////////////////
// 스타일 정의
// 해당 위젯의 CSS 스타일을 정의합니다.
////////////////////////////////////////////////////////
export function style(data) {
  let layout = {}
  let object = {}
  let property = Object.keys(data)
  if(property.includes('position')){
    layout = Object.assign(layout,common.position({widget:data.position}))
  }
  if(property.includes('size')){
    object = Object.assign(object,common.size({widget:data.size}))
  }
  if(property.includes('style')){
    // 외곽선 
    object = Object.assign(object,common.border({widget:data.style}))
    // 폰트
    object = Object.assign(object,common.font({widget:data.style}))
    // 필터 + 그림자 + 투명도
    object = Object.assign(object,common.shortFilter({widget:data.style}))
    // 모핑
    layout = Object.assign(layout,common.morphing({widget:data.animation}))
    object = Object.assign(object,common.morphing({widget:data.animation}))
    //배경색
    if (data.style.useFill){
      object = Object.assign(object,common.fill({widget:data.style}))
    }
    // 변형
    object = Object.assign(object,common.transform({widget:data.style}))

    object = Object.assign(object,videoShadow({widget:data.style}))

  }

  return {layout,object}
}
  
////////////////////////////////////////////////////////
// 프리셋 정의
// 해당 위젯의 CSS  프리셋을 정의합니다.
////////////////////////////////////////////////////////
// 프리셋을 사용하지 않는 위젯의 경우
export const Preset = null
// 프리셋을 사용하는 위젯의 경우
/* export const Preset = [
    {name:'overwatch_normal', nameKo:'오버워치 기본형 스코어보드', size:{width: 1920, height: 108},position:{x: 0, y: 0,}},
] */


////////////////////////////////////////////////////////
// 애니메이션 정의
// 해당 위젯의 기본 설정값을 넣습니다.
////////////////////////////////////////////////////////


/**
 * 사용 가능한 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} preset // 위젯의 프리셋
 * @returns 
 */
export function availableAnimation (state,preset) {

  const List = keyframe.basic(state)

  let customList = {}

  switch (preset) {
    case 'presetName':
      if(state == 'mountStart'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }

      else if(state == 'mountEnd'){
        customList = {
          'custom' :  {
            info : {name:'fade in',nameKo:'페이드인',icon:'blur_on'},
            timing : {max:5000,min:500,default:1000},
            target :{layout:{keyframe:'widget_animation_fadeIn'}},
          },
        }
      }
      break;
    default:
      break;
  }

  const MergedList = Object.assign( customList, List );
  return MergedList;
}
/**
 * animation 애니메이션 효과를 정의합니다.
 * @param {*} state // 애니메이션 효과 종류 mountStart&mountEnd
 * @param {*} data  // 위젯의 데이터
 * @param {*} preset // 해당 프리셋에서 유효한 애니메이션인지 검증합니다. 
 * @returns 
 */
export function animation (state,data,preset=null) {
  let output = {}
  switch (state) {
    case 'mountStart':

    const List = availableAnimation(state,preset)
    output = keyframe.css({widgetAnimation:data.animation[state],availableAnimation:List})
    break;

    default:
      break;
  }
  return output
}
////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////

function minSize (property,width='200px',height='200px') {
  let keys = Object.keys(property)
  let result = {
    width,
    height,
  }

  if(keys.includes('width')){
    if (property.width=='auto'){
      result['minWidth'] = 'auto'
    }else {
      result['minWidth'] = property.width +'px'
    }
  }

  if(keys.includes('height')){
    if (property.result=='auto'){
      result['minHeight'] = 'auto'
    }else {
      result['minHeight'] = property.height +'px'
    }
  }
  return result
}

////////////////////////////////////////////////////////
// 커스텀 펑션 정의
// 해당 위젯 및 위젯 모델에서 사용하는 평선을 정의합니다.
////////////////////////////////////////////////////////
function getVideoType (mediaSource) {
  if (typeof(mediaSource) != 'string'){
    return false;
  }

  const YoutubeShort = 'https://youtu.be/'
  if (mediaSource.toLowerCase().includes(YoutubeShort.toLowerCase())){
      return 'youtube';
  }
  const YoutubeFull = 'https://www.youtube.com/'
  if (mediaSource.toLowerCase().includes(YoutubeFull.toLowerCase())){
      return 'youtube';
  }
}

function getYoutubeID (mediaSource) {
  const YoutubeShort = 'https://youtu.be/'
  if (mediaSource.toLowerCase().includes(YoutubeShort.toLowerCase())){
      let videoId = mediaSource.split('/').pop();
      videoId = videoId.split('?')[0];
      return videoId;
  }
  const YoutubeFull = 'https://www.youtube.com/'
  if (mediaSource.toLowerCase().includes(YoutubeFull.toLowerCase())){
      let videoId = mediaSource.split('/watch?v=').pop();
      console.log('videoId',videoId)
      videoId = videoId.split('?')[0];

      return videoId;
  }
}


export function autoPlay({widget}) {
  if(!widget){
  return null
  }
  const now = Math.floor(Date.now() / 1000)

  return {playCache_state:'play',playCache_start:now,playCache_progress:0}
}


function videoShadow({widget}) {
  let keys = Object.keys(widget)
  let result = {}
  let shadowColor = null
  let shadowValue = null
  if(keys.includes('shadowColor')){
    shadowColor = widget['shadowColor']
  }
  if(keys.includes('shadowValue')){
    shadowValue = Number(widget['shadowValue'])
  }
  // if(keys.includes('shadowDirection')){
  //   shadowDirection = widget['shadowDirection']
  // }
  if(shadowValue!=0){
    result['boxShadow'] = videoShadowOption({strong : shadowValue, color : shadowColor})
  }
  
  return result
}

function videoShadowOption ({strong,color='#000000',option="soft"}) {

  // if(!strong){return ''}  
  // let res = ''
  // let opacity = Math.round(strong/10 * 255).toString(16)

  function hexToRgba(hex, alpha) {
    // Hex 코드가 #으로 시작하면 제거합니다.
    hex = hex.replace(/^#/, '');
  
    // 짧은 형태의 Hex 코드(3글자)인 경우, 각 글자를 두 번 반복하여 6글자 형태로 변환합니다.
    if (hex.length === 3) {
      hex = hex.split('').map(char => char + char).join('');
    }
  
    // Hex 코드를 RGB 값으로 변환합니다.
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    // 투명도를 0과 1 사이로 제한합니다.
    alpha = Math.max(0, Math.min(1, alpha));
  
    // RGBA 문자열을 반환합니다.
    return `rgba(${r}, ${g}, ${b}, ${alpha.toFixed(2)})`;
  }
  
 const val = strong*0.05

  
  if(option='soft'){
    return(
      `0 2px 4px ${hexToRgba(color,val)}, 0 6px 8px ${hexToRgba(color,val)}, 0 12px 16px rgba(73, 25, 25, 0.1), 0 18px 24px ${hexToRgba(color,val)} `
    )
  }

}
