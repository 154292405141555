////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import ViewInfo from "components/Lobby/space/module/ViewInfo"
import AppendFile from 'components/Edit/editPanel/append/AppendFile';
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoAnimate from 'assets/logoAnimate.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as notice from 'service/api/notice' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AdminModulePost({createPost,readPost}) {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [SelectPost, setSelectPost] = useState('notice'); 

    const [PostList, setPostList] = useState(undefined); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    //   useEffect(() => {

    //     return () => {
    //     };
    //   },[]);


      useEffect(() => {
        const getPost =  async () => {
            const Posts =  await notice.getList(SelectPost)
            console.log('Posts',Posts);
            setPostList(Posts)
  
        }
        getPost();
        setTimeout(() => {
            getPost()
        }, 1000);
        return () => {
        };
      },[SelectPost]);

    //   useEffect(() => {
    //     console.log('Component did mount or update');
    //     setTimeout(function() {
    //         getFileList()
    //       }, 1000);
    //     // Cleanup function (optional)
    //     return () => {
    //       console.log('Component will unmount');
    //     };
    //   },[props.mode]);

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    const getList = () => {

        switch (PostList) {
            case undefined:
                
                return(
                    <div className='post_placeholder'>
                        <img src={logoAnimate} width={200} height={200}  alt="Logo Animation" />
                    </div>
                )
            case false:
        
                return(
                    <div className='post_placeholder'>
                        <img src={logoAnimate} width={200} height={200}  alt="Logo Animation" />
                    </div>
                )
        
            default:
            const List = PostList.map((post) => (
                <div className='post_preview' onClick={()=>{readPost(post?.id)}}>
                    <div className='post_header'>
                        <div className='post_left'>
                            <div className='post_title'>
                                {post?.title}
                            </div>
                        </div>
                        <div className='post_right'>
                            <div className='post_Date'>
                            {timeAgo(post?.created_at)}
                            </div>
                            <div className='post_modify'>
                                수정
                            </div>
                        </div>
                    </div>
                    <div className='post_content'>
                        {post?.content?.[0]?.content}
                    </div>
                </div>

            ))
    
            return List;
                


        }

    }

    return (
        <div className='AdminModulePost AdminModule' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <div className='PostField'>

        <div  className='TabHeader'>
        <div className='TabTitle'>
        게시글 관리 
        </div>

        <nav className='TabNav'>
            <div className='TabLeft'>
                <div className={ SelectPost == 'notice' ? 'TabItem TabSelect' : 'TabItem' } onClick={()=>setSelectPost('notice')}>
                    공지사항
                </div>
                <div className={ SelectPost == 'update' ? 'TabItem TabSelect' : 'TabItem' } onClick={()=>setSelectPost('update')}>
                    업데이트
                    
                </div>
            </div>

            <div className='TabRight'>
                <div className='TabAction' onClick={() => createPost(SelectPost)}>
                    새 글 작성하기
                </div>
            </div>
    
        </nav>
            
        </div>

        <div className='postList'>
            {getList()}

        </div>


  
        </div>
    </div>
    );
  }

  export default AdminModulePost;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminModule {

    }

`;

const ContainerStyle = `
   .AdminModulePost{
        display: flex;
        margin : 0px 20px;
        width : 100%;
    }


    .PostField{
        display: flex;
        width : 100%;
        flex-direction: column;
    }

    .TabHeader{
    }
    

    .TabTitle{
        display flex;
        width : 100%;
        height : 50px;
        font-size : 25px;
    }
    
    .TabNav{
        display : flex;
        flex-direction: row;
        width : 100%;
        height : 50px;
        border-bottom : 4px solid #999;
        justify-content: space-between;
    }
    .TabLeft{
        display : flex;
        justify-content: start;
    }
    .TabRight{
        display : flex;
        justify-content: end;
    }

    .TabItem{
        display : flex;
        width : 100px;
        padding : 5px 0px;
        height : 40px;
        align-items: center;
        justify-content: center;
        color : #999;
    }

    .TabAction{
        display : flex;
        width : 100px;
        padding : 5px 0px;
        height : 40px;
        align-items: center;
        justify-content: center;
        color : #999;
    }
    
    .TabSelect{
        color : #fff;
        border-bottom : 4px solid #fff;
    }
    
    .post_placeholder{
        display : flex;
        width : 100%;
        height : 100%;
        min-height : 300px;
        align-items: center;
        justify-content: center;
    }

    .post_preview{
        display : flex;
        flex-direction: column;
        width : 100%;
        height : 100px;
        margin : 20px 0px;
        border-bottom : 1px solid #999;
    }


    .post_preview:hover{
        background-color : #333;
    }

        
    .post_header{
        display : flex;
        width : 100%;
        height : 30px;
        justify-content: space-between;
    }
    
    .post_content{
        display : flex;
        width : 100%;
        font-size : 15px;
        line-height : 20px;
        height : 60px;
        overflow : hieddn;
    }

    .post_left{
        display : flex;
        width : 50%;
        align-items: center;
        justify-content: start;
        align-items: end;
    }
    .post_right{
        display : flex;
        width : 30%;
        align-items: center;
        justify-content: end;
        align-items: end;
    }

    .post_modify{
        margin-left : 20px;
    }

    .post_title{
        display : flex;
        width : 100%;
        flex-direction: row;
        font-size : 25px;
        line-height : 30px;
        height : 30px;
        justify-content: space-between;
    }
    
`;



function timeAgo(dateString) {
    const then = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((now - then) / 1000);
  
    if (diffInSeconds < 180) {
      return `지금`;
    }
    if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)}분 전`;
    }
    if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)}시간 전`;
    }
    return `${Math.floor(diffInSeconds / 86400)}일 전`;
  }