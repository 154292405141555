////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import chzzkLogo from 'assets/sidebar/chat/chzzk.jpg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import AppendProfileCreate from 'components/Edit/editPanel/append/AppendProfileCreate';
import AppendProfileList from 'components/Edit/editPanel/append/AppendProfileList';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendSource({sourceID,sourceLabel,sourcePreview,sourceIcon,deviceId,sourceState}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [AreaState, setAreaState] = useState('list');
    const [PreviewImage, setPreviewImage] = useState(null);

    // state
    const [SelectProfile, setSelectProfile] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        // getFile();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


      function arrayBufferToBlob(arrayBuffer, mimeType = 'application/octet-stream') {
        return 
      }
      

      
      useEffect(() => {

        if(sourcePreview){
          const blob = new Blob([sourcePreview], { type: 'image/jpeg' });
          const PreviewImage = URL.createObjectURL(blob);
          setPreviewImage(PreviewImage)
        }

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [sourcePreview]);
   



    return (
      <div className='appendSource'>
          <div className='previewArea'>
            {PreviewImage?
            <div className='sourceImage sourcePreview'>
              <img className='PreviewImage' src={PreviewImage}/>
            </div>
            :
            <div className='sourceImage sourceIcon'>
            {Editor.icon({icon:'videocam',size:130,lineHeight:200})}
            </div>
            }
          </div>
          <div className='infoArea'>
            <div className='sourceName'>{sourceLabel}</div>
            {/* <div className='sourceInfo'>{deviceId}</div> */}
          </div>

        </div>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendSource);
  