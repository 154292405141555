
////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결

// 라이브러리 
import io from 'socket.io-client';
// 설정 파일
import * as config from 'config/OverlapConfig'
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 이미지 연결

// 서비스 연결
import * as Rest from 'service/api/rest' ;
import { token } from 'service/event/Account' ; 

////////////////////////////////////////////////////////
// store 연결
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
/** create
 * @param title 게시글 제목을 적습니다.
 * @param content 컨텐츠 내용을 적습니다.
 * @param category 컨텐츠 카테고리
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function create(title, content, category) {

    let response =  await Rest.request('/notice/create','Post',{title, content, category},token('account'));
    if(response?.status == 200){
        return true;
    }
    return false;
}

////////////////////////////////////////////////////////
/** create
 * @param title 게시글 제목을 적습니다.
 * @param content 컨텐츠 내용을 적습니다.
 * @param category 컨텐츠 카테고리
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function update(id, title, content, category) {

    let response =  await Rest.request('/notice/update','Post',{post_id:id, title, content, category},token('account'));
    if(response?.status == 200){
        return true;
    }
    return false;
}


////////////////////////////////////////////////////////
/** create
 * @param title 게시글 제목을 적습니다.
 * @param content 컨텐츠 내용을 적습니다.
 * @param category 컨텐츠 카테고리
 * @returns 오버랩 IO 객체
 */
////////////////////////////////////////////////////////

export async function del(id) {

    let response =  await Rest.request('/notice/delete','Post',{post_id:id},token('account'));
    if(response?.status == 200){
        return true;
    }
    return false;
}


////////////////////////////////////////////////////////
/** getList
 * @param category 게시글을 가지고 올 카테고리를 선택합니다.
 * @returns 게시글 리스트
 */
////////////////////////////////////////////////////////

export async function getList(category) {
    console.log('category',category);
    let response =  await Rest.request('/notice','get',{category},token('account'));
    if(response?.status == 200){
        return response.data.data;
    }
    return false;
}



////////////////////////////////////////////////////////
/** getList
 * @param category 게시글을 가지고 올 카테고리를 선택합니다.
 * @returns 게시글 리스트
 */
////////////////////////////////////////////////////////

export async function get(id) {
    let response =  await Rest.request('/notice/post','get',{post_id:id},token('account'));
    if(response?.status == 200){
        return response.data.data?.[0]??null;
    }
    return false;
}


// ////////////////////////////////////////////////////////
// /** templateGet
//  * @param spaceId 오버랩을 생성할 스페이스의 ID를 적습니다.
//  * @param data 오버랩 생성에 필요한 정보를 적습니다. {title,resolution,private,private,summary}
//  * @returns 오버랩 IO 객체
//  */
// ////////////////////////////////////////////////////////

// export async function get(tag) {

//     let response =  await Rest.request('/template/get','get',{tag:tag},token('account'));
//     console.log('체크',response?.data?.status)
//     if(response?.status == 200){
//         return response.data.data;
//     }
//     return false;
// }

// ////////////////////////////////////////////////////////
// /** templateGetPageForUUID
//  * @param uuid 가지고 올 템플릿의 UUID 를 가져옵니다.
//  * @returns 오버랩 IO 객체
//  */
// ////////////////////////////////////////////////////////

// export async function getPageForUUID(uuid) {

//     let response =  await Rest.request('/template/page/uuid','get',{"uuid":uuid},token('account'));
//     console.log('/template/page/uuid response',response)
//     if(response?.status == 200){
//         console.log('response',response)

//         return response?.data?.data;
//     }
//     return [];
// }

// ////////////////////////////////////////////////////////
// /** templateGetPageForUUID
//  * @param uuid 가지고 올 템플릿의 UUID 를 가져옵니다.
//  * @returns 오버랩 IO 객체
//  */
// ////////////////////////////////////////////////////////

// export async function getTemplateForUUID(uuid) {

//     let response =  await Rest.request('/template/get/uuid','get',{"uuid":uuid},token('account'));
//     console.log('/template/page/uuid response',response)
//     if(response?.status == 200){
//         console.log('response',response)

//         return response?.data?.data;
//     }
//     return [];
// }


// export async function getTemplateConcatForUUID(uuid) {

//     let response =  await Rest.request('/template/get/concat/uuid','get',{"uuid":uuid},token('account'));
//     console.log('/template/page/uuid response',response)
//     if(response?.status == 200){
//         console.log('response',response)

//         return response?.data?.data;
//     }
//     return [];
// }
