////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component , Fragment } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결

// 위젯 


// 이미지 연결
import logoW from 'assets/logoW.svg';
import logo from 'assets/logo.svg';
import twitchLogo from 'assets/main/twitchlogo.png';
import edit from 'assets/main/edit.png';
import bot from 'assets/main/bot.png';
import control from 'assets/main/control.png';
import banner1 from 'assets/main/banner1.png';

// 컴포넌트 연결
import SpaceModuleAdminDashboard from 'pages/lobby/space/sm_admin/SpaceModuleAdmin';


// 서비스 연결
import * as Space from 'service/api/space' ; 
import * as guide from 'service/event/guide';

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
class SpaceAdmin extends Component {
    
    state = {
        mode : null,
        select : null,
        overlap : null,
    }

    async componentDidMount () {
        
    }

    constructor(props) {
        super(props);
        this.spaceRef = React.createRef();
      }
      
    componentDidUpdate(prevProps){
        
        
    }
   


    render() { 
        return (
            <div className = "Lobby_innerPage" >
                <SpaceModuleAdminDashboard/>
            </div>
        )
    }
}
////////////////////////////////////////////////////////
// static 변수, 함수 정의부
////////////////////////////////////////////////////////


////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////


  // export default Login;
  export default SpaceAdmin;
  