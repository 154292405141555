////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
// 라이브러리 

// [리덕스]스토어 연결

// [프로바이더]

// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 이미지 연결

// 컴포넌트 연결


// 서비스 연결

////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendPanelGuide({context,panelHeight,panelWidth}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    const [WidgetButtonsList, setWidgetButtonsList] = useState([]);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        // setWidgetList()
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

      



    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section  id = 'AppendPanelGuide' style={{height:panelHeight,width:panelWidth}}>
                  <div className='GuideMessage'>
                    지금은 위젯을 추가 할 수 없습니다.
                  </div>
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendPanelGuide);
  


  const EditPageStyle = `

  #AppendPanelGuide {
    top : 40px;
    display: flex;
    flex : 1 ;
    flex-wrap: wrap;
    overflow: auto;
    background: #00000099; /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #00000099, #00000099); 
    background: linear-gradient(to top, #000000CC, #00000099); 
    position: absolute;
    z-index: 10;
    border-radius: 0px 0px 5px 5px;
    animation: AppendPanelGuideFadeInUp 0.3s ease-out forwards; 
  }

  #AppendPanelGuide .GuideMessage {
    color: #adadad;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex : 1 ;
    display: flex;
    font-size: 30px;
  }

  @keyframes AppendPanelGuideFadeInUp {
    from {
      opacity: 0;

    }
    to {
      opacity: 1;

    }
  }



  `;

  