

////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect, useRef} from 'react';
import * as Editor from 'service/edit/EditService' ; 
import { Route, Link, useParams} from 'react-router-dom';
// [리덕스]스토어 연결

// 이미지 연결
import logoAnimate from 'assets/logoAnimate.svg';
import template from 'assets/main/lobby/project/template.svg';
// 컴포넌트 연결

// SASS&CSS 연결
import "sass/frontPage.scss"

// 서비스 연결
import * as notice from 'service/api/notice' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////


function PromotionDonation () {
    const { type} = useParams(); // URL 파라미터에서 'id'를 가져옵니다.
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [SelectPost, setSelectPost] = useState('notice'); 

    const [PostList, setPostList] = useState(undefined); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    return  (
    <Fragment>
    <style jsx>{`${PageStyle}`}</style>
    
      <div id = "PromotionDonation" className='' >
       <div id = "BackImage">
            <img src={template} width={'100%'} height={'100%'}/>
       </div>
       <div id = "DonationCard">
        <h2 id = "DonationHeader">
        오버렙의 후원자가 되어주세요
        </h2>
        <p id = "DonationInfo">
        오버랩은 누구나 간단하고 편리하게 라이브 스트리밍을 즐길 수 있도록 돕는 혁신적인 서비스입니다
        여러분의 후원은 오버랩이 더 많은 사람들에게 가치 있는 서비스를 제공할 수 있도록 힘을 실어줍니다
        </p>

        <p id = "DonationAccount">
            카카오 뱅크 3333-00000-00000
        </p>



       </div>
        {/* <div id= "MoveHome" > */}
        <Link id="MoveHome" to="/">
            {Editor.icon({icon:'arrow_back',size:25,lineHeight:30})} &nbsp; 뒤로 돌아가기              
            </Link>
        {/* </div> */}
    
      </div>
      </Fragment>
    );

}

export default PromotionDonation;



const PageStyle = `

#PromotionDonation{
    display :flex;
    flex-direction: column;
    width: 100vw; /* 100% of viewport width */
    height: 100vh; /* 100% of viewport height */
    flex : 1 ;
    color : #fff;
    background-color : #222;
    font-family: '프리텐다드ExtraBold';
    align-items: center;
    justify-content: end;
}

#PromotionDonation #BackImage{
    position: absolute;
    width: 100vw; /* 100% of viewport width */
    height: 100vh; /* 100% of viewport height */
    z-index: 0;
}

#PromotionDonation #BackImage img {
    object-fit : cover;
    width : 100%;
    height : 100%;
    display :flex;
}

#PromotionDonation #DonationCard {
    z-index: 1;
    position: absolute;
    width : 700px;
    height : 300px;
    margin-bottom : 50px;
    background: #444444;
    border-radius : 10px;
    display :flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

#DonationHeader{
    width : 600px;
    font-size :50px;
    margin : 0;
    margin-bottom : 20px;
    text-align : center;
}

#DonationInfo{
    width : 600px;
    font-size : 18px;
    text-align : left;
    margin-left : 10px;
    line-height : 25px;
}

#DonationAccount{
    padding : 0px 20px;
    height : 40px;
    background: #333;
    margin-top : 20px;
    border-radius : 10px;
    display :flex;
    align-items: center;
    justify-content: center;
}

#MoveHome{
    z-index: 2;
    position: absolute;
    width : 150px;
    height : 50px;
    color : #fff;
    text-decoration: none;
    top : 20px;
    left : 20px;
    background: #444444;
    border-radius : 10px;
    display :flex;
    align-items: center;
    justify-content: center;
}
   
`;
