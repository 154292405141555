////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, useContext, Fragment, useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 
import { useResizeDetector } from 'react-resize-detector';// 컴포넌트의 사이즈 감지
// [리덕스]스토어 연결
import store from "store";
// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
import AppendWidgetArea from 'components/Edit/editPanel/append/AppendWidgetArea';// 위젯을 추가 패널
import AppendTeamArea from 'components/Edit/editPanel/append/AppendTeamArea';// 팀 추가 패널
import AppendFileArea from 'components/Edit/editPanel/append/AppendFileArea';// 라이브러리 추가 패널
import AppendImageArea from 'components/Edit/editPanel/append/AppendImageArea';// 이미지 선택 패널
import AppendProfileArea from 'components/Edit/editPanel/append/AppendProfileArea';// 라이브러리 추가 패널
import AppendPanelGuide from 'components/Edit/editPanel/append/AppendPanelGuide';// 위젯 가이드
import AppendCameraArea from 'components/Edit/editPanel/append/AppendCameraArea';// 카메라 선택 패널

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from'service/other/Utility.js' ; 


////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendPanel({sizeDetector}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const context = useContext(EditContext);
    // state
    const [PanelState, setPanelState] = useState('widget');
    const [PanelHeight, setPanelHeight] = useState(240);
    const [PanelOption, setPanelOption] = useState(false);
    const [WidgetCategory, setWidgetCategory] = useState('all');

    // rel 
    const { width, height, ref } = useResizeDetector();

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

    // [useEffect] sizeDetector 엘리먼트의 사이즈를 측정합니다. 
    useEffect(() => {
      if(width!=undefined||height!=undefined){
        sizeDetector('AppendPanel',width,height)
      }
    }, [width,height]);

    useEffect(() => {
      switch (context.panel?.bottom?.state) {
        case 'widget':

          setPanelHeight(240)
          setPanelOption(false)
          break;
        case 'team':

          setPanelHeight('full')
          setPanelOption(false)
          break;
        case 'library':

          setPanelHeight(500)
          setPanelOption(false)
          break;
        case 'addImage':
       
          setPanelHeight('full')
          setPanelOption(false)
          break;                 
        case 'profile':
          setPanelHeight('full')
          setPanelOption(false)
          break;    
        case 'camera':
          setPanelHeight('full')
          setPanelOption(false)
          break;    
        default:
          break;
      }
    }, [context.panel?.bottom?.state]);


    // useEffect(() => {
    //   // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
    //   setPanelOption(false)
    //   return () => {
    //   // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
    //   };
    // }, [context,context.panel.bottom.state]);

     ////////////////////////////////////////////////////////
    // 
    ////////////////////////////////////////////////////////
    /**
         * 위젯 데이터를 수정합니다. 단일 수정 및 다중수정을 지원합니다. 
         * 단일수정 : modify(category,key,val,uuid)
         * 다중수정 : modify(category,[key1,key2],[val1,val2],uuid) 또는 modify([category1],[category2],[key1,key2],[val1,val2],uuid)
         * @param {*} category 위젯의 수정할 카테고리, cat1 또는 [cat1,cat2]
         * @param {*} keyName 위젯의 수정할 키, key1 또는 [key1,key2]
         * @param {*} value 위젯의 수정할 카테고리, val1 또는 [val1,val2]
         * @param {string} uuid 대상위젯
         * @returns 
         */
    const modifyWidget = (category,keyName,value,uuid) => {

      console.log('modifyWidget',category,keyName,value,uuid)
      let keyList 
      let valueList 
      let categoryList 
      // 위젯의 단일 속성 수정인지, 복수의 속성 수정인지를 keyName이 배열인지 아닌지 보고 판단합니다.
      if (typeof keyName === 'string') {
        keyList = [keyName]
        valueList = [value]
        categoryList = [category] 
      } else if (Array.isArray(keyName)) {
        keyList = keyName
        valueList = value
        categoryList = Array.isArray(category) ? category : [category];
      } else {
        return 
      }

      // 키리스트 숫자만큼 값 리스트가 있는지 확인합니다.
      if (keyList?.length != valueList?.length){
        console.error(`modifyWidget : \n ⛔️ 위젯의 여러 값을 동시에 수정할때는 [키,키] [값,값] 형식으로 키의 숫자만큼 값을 넣어야합니다.`);
        return
      }

      // 카테고리가 하나거나 또는 키 리스트 만큼 있는지 확인합니다.
      if (!(categoryList.length === 1 || categoryList?.length === keyList?.length)) {
        console.error(`modifyWidget : \n ⛔️ 위젯의 카테고리는 하나거나 키숫자만큼의 배열이어야 합니다. 하나면 전역적용 배열이면 각각 순서에 맞춰 적용됩니다.`);
        return;
      }

      const TargetWidget = context.findwidget(uuid)?.[0];    
      const ModifyData = {}
      for (let index = 0; index < keyList.length; index++) {
        const Category = categoryList.length == 1 ? categoryList[0] :categoryList[index];
        Utility.setNestedObject(ModifyData,[Category,keyList[index]],valueList[index])
      }
      const WidgetData = TargetWidget.data;
      const MergeData = Utility.mergeDeep(ModifyData ,WidgetData)
      TargetWidget.data = MergeData;
      context.modifyWidget(TargetWidget,'tapWidget')
    }


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    let area = null

    switch (context.panel.bottom.state) {
      case 'widget':
        area =(
          <Fragment>
            <AppendWidgetArea panelHeight={180} panelWidth={width} context={context} category={WidgetCategory} />
            {context?.selectPageIndex==-1||context?.selectPageIndex==null? <AppendPanelGuide panelHeight={height-40} panelWidth={width} context={context}/> :null}
          </Fragment>
        )

      break;

      case 'team':
        area =(
          <Fragment>
            <AppendTeamArea panelHeight={height-40} panelWidth={width} context={context}/>
          </Fragment>
        )
      break;
      case 'library':
        area =(
          <Fragment>
            <AppendFileArea panelHeight={height-40} panelWidth={width} context={context}  />
          </Fragment>
        )
      break;  
      
      case 'addImage':
        area =(
          <Fragment>
            <AppendImageArea panelHeight={height-40} panelWidth={width} context={context} modifyWidget={modifyWidget}/>
          </Fragment>
        )
      break;  

      case 'profile':
        area =(
          <Fragment>
            <AppendProfileArea panelHeight={height-40} panelWidth={width} context={context} modifyWidget={modifyWidget}/>
          </Fragment>
        )
      break; 

      case 'camera':
        area =(
          <Fragment>
            <AppendCameraArea panelHeight={height-40} panelWidth={width} context={context} modifyWidget={modifyWidget}/>
          </Fragment>
        )
      break; 

      case 'screen':
        area =(
          <Fragment>
            <AppendImageArea panelHeight={height-40} panelWidth={width} context={context} modifyWidget={modifyWidget}/>
          </Fragment>
        )
      break; 
      default:
        break;
    }




    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <div id = "AppendPanel" style={{height:PanelHeight=='full'?'calc(100vh - 40px)':PanelHeight}} ref={ref}>
                <nav id = 'AppendNav'>
                    <div id = "ProjectState">
                         {context.panel.bottom.state!='widget'? 
                         <li className={PanelState == 'team' ?'selectOptions widgetFilterButton' : 'widgetFilterButton'} onClick={()=>context.panelControl('bottom','widget')}> 
                         {Editor.icon({icon:'stat_minus_1',size:25,lineHeight:30})}
                          </li>
                          :null}
                    </div>
                    <div id = "ProjectOption">
                       {context.panel.bottom.state=='widget'?
                        <ul id = "WidgetOption">   
                            <li className={WidgetCategory == 'test' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('test')}> 
                              {Editor.icon({icon:'science',size:18,lineHeight:20})}
                            </li>     

                            <li className={WidgetCategory == 'text' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('text')}> 
                              {Editor.icon({icon:'font_download',size:18,lineHeight:20})}
                            </li>
                                 
                            <li className={WidgetCategory == 'file' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('file')}> 
                              {Editor.icon({icon:'note_add',size:18,lineHeight:20})}
                            </li>

                            <li className={WidgetCategory == 'shape' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('shape')}> 
                              {Editor.icon({icon:'shapes',size:18,lineHeight:20})}
                            </li>

                            <li className={WidgetCategory == 'utility' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('utility')}> 
                              {Editor.icon({icon:'timer_play',size:18,lineHeight:20})}
                            </li>

                            <li className={WidgetCategory == 'effect' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('effect')}> 
                              {Editor.icon({icon:'celebration',size:18,lineHeight:20})}
                            </li>
                                                            
                            <li className={WidgetCategory == 'game' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('game')}> 
                              {Editor.icon({icon:'sports_esports',size:18,lineHeight:20})}
                            </li>
                            
                            <li className={WidgetCategory == 'all' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('all')}> 
                              {Editor.icon({icon:'widgets',size:18,lineHeight:20})}
                            </li> 
                        </ul>
                        :null}
                        {context.panel.bottom.state=='library'?
                        <ul id = "WidgetOption">                                
                            {/* <li className={WidgetCategory == 'text' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('text')}> 
                              {Editor.icon({icon:'font_download',size:18,lineHeight:20})}
                            </li>
                                 
                            <li className={WidgetCategory == 'file' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('file')}> 
                              {Editor.icon({icon:'note_add',size:18,lineHeight:20})}
                            </li>

                            <li className={WidgetCategory == 'shape' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('shape')}> 
                              {Editor.icon({icon:'shapes',size:18,lineHeight:20})}
                            </li>

                            <li className={WidgetCategory == 'utility' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('utility')}> 
                              {Editor.icon({icon:'timer_play',size:18,lineHeight:20})}
                            </li>

                            <li className={WidgetCategory == 'effect' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('effect')}> 
                              {Editor.icon({icon:'celebration',size:18,lineHeight:20})}
                            </li>
                                                            
                            <li className={WidgetCategory == 'game' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('game')}> 
                              {Editor.icon({icon:'sports_esports',size:18,lineHeight:20})}
                            </li>
                            
                            <li className={WidgetCategory == 'all' ?'select filterButton' : 'filterButton'} onClick={()=>setWidgetCategory('all')}> 
                              {Editor.icon({icon:'widgets',size:18,lineHeight:20})}
                            </li>  */}
                        </ul>
                        :null}
                         {context.panel.bottom.state=='camera'?
                        <ul id = "SourceOption">                                
                            {/* <li className={WidgetCategory == 'text' ?'select optionButton' : 'optionButton'} onClick={()=>setWidgetCategory('text')}> 
                              {Editor.icon({icon:'font_download',size:18,lineHeight:20})}
                              내 디바이스 공유하기
                            </li> */}
                        </ul>
                        :null}
                        <div className='panelOption'>
                          {PanelOption ? 
                          
                          <div className={'optionList '+(context.panel.bottom.state=="widget"?'optionSmall':'optionFull')}>
                            {/* <div className={'options '+(context.panel.bottom.state=="profile"?'select':'')} onClick={()=>context.panelControl('bottom','profile')}> 
                              <div className='optionImg'>
                              {Editor.icon({icon:'id_card',size:25,lineHeight:30})}
                              </div>
                              <div className='optionName'>
                                <p>프로필</p>
                                <small>
                                외부 서비스와 연결합니다.
                                </small>
                              </div>
                            </div> */}
                            <div className={'options '+(context.panel.bottom.state=="team"?'select':'')} onClick={()=>context.panelControl('bottom','team')}> 
                              <div className='optionImg'>
                              {Editor.icon({icon:'group',size:25,lineHeight:30})}
                              </div>
                              <div className='optionName'>
                                <p>팀원</p>
                                <small>
                                팀과 팀원을 편집합니다.
                                </small>
                              </div>
                            </div>
                            <div className={'options '+(context.panel.bottom.state=="library"?'select':'')}  onClick={()=>context.panelControl('bottom','library')}> 
                              <div className='optionImg'>
                              {Editor.icon({icon:'folder_open',size:25,lineHeight:30})}
                              </div>
                              <div className='optionName'>
                                
                                <p>라이브러리</p>
                                <small>
                                  다양한 파일을 가져옵니다.
                                </small>
                              </div>
                            </div>
                            <div className={'options '+(context.panel.bottom.state=="widget"?'select':'')}  onClick={()=>context.panelControl('bottom','widget')}> 
                              <div className='optionImg'>
                              {Editor.icon({icon:'widgets',size:25,lineHeight:30})}
                              </div>
                              <div className='optionName'>
                                
                                <p>위젯</p>
                                <small>
                                  오버랩에 위젯을 추가합니다.
                                </small>
                              </div>
                            </div>
            
                          </div>:null}
                          <button className='optionButton' onClick={()=>setPanelOption(!PanelOption)}>
                          {Editor.icon({icon:'more_horiz',size:30,lineHeight:30})}
                          </button>

                        </div>
                    </div>              
                </nav>
                <div className='PanelContent'>
                {area}
                </div>
                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendPanel);
  


  const EditPageStyle = `

  #AppendPanel {
    background-color: #3F3F3F;
    border-radius: 5px;
    height: 240px;

    transition: height 0.3s ease;
    display: flex;
    flex-direction: column;
  }

  #AppendPanel #AppendNav{
    height: 40px;
    background-color: #2C2C2C;
    display: flex;
    border-radius: 5px 5px 0px 0px;
    justify-content: space-between;
  }

  #AppendPanel #ProjectState{
    display: flex;
    justify-content: flex-start;
  }


  #AppendPanel #ProjectOption{
    display: flex;
    justify-content: flex-end;
  }


  #AppendPanel #WidgetOption{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  #AppendPanel .widgetFilterButton{
    width: 50px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #AppendPanel #ProjectState .detailOption{
    height: 30px;
    width: calc(100% - 20px);
    padding: 0px 5px;
    line-height: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    box-shadow: 1px 1px 5px #1c1c1c;
    background-color: #444;
    margin: 5px;
    border-radius: 50px;
  }

  #AppendPanel #ProjectOption .detailOption{
    height: 40px;
    display: flex;
    justify-content: end;
    line-height: 40px;
  }


  #AppendPanel #ProjectOption .panelOption{
    height: 40px;
    width : 50px;
  }

  #AppendPanel #ProjectOption .optionButton{
    background : #333;
    display: flex;
    
    justify-content: end;
    justify-content: center;
    align-items: center;
    border-radius : 30px;
    margin : 5px 10px;
    position: relative;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out,  background 0.1s ease-out;
  }
  #AppendPanel #ProjectOption .optionButton:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border-radius: 10px;
    z-index: -1;
}


  #AppendPanel #ProjectOption  .optionButton:active{
    transform: scale(0.80);
      background-color: #1C1C1C;
  }
  #AppendPanel #ProjectOption .optionButton .icon{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #AppendPanel #ProjectOption .optionButton:active:before {
       transform: scale(1.25);
  }


  #AppendPanel #ProjectOption .panelOption .optionList{
    position: absolute;
    z-index : 5;
    height: auto;
    width : 200px;
    border-radius : 5px;
    background : #444;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  }

  #AppendPanel #ProjectOption .panelOption .optionList.optionSmall{
    bottom : 250px;
    right : 0px;
  }
  #AppendPanel #ProjectOption .panelOption .optionList.optionFull{
    top : 50px;
    right : 10px;
  }

  #AppendPanel #ProjectOption .panelOption .optionList .options{
    height: 50px;
    margin : 5px;
    width : 190px;
    border-radius : 5px;
    background : #333;
    display : flex;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08);
  }

  #AppendPanel #ProjectOption .panelOption .optionList .options.select{
    background : #111;
  }
  #AppendPanel #ProjectOption .panelOption .optionList .options .optionImg{
    height: 50px;
    width : 50px;
    display : flex;
    justify-content: center;
    align-items: center;
  }

  #AppendPanel #ProjectOption .panelOption .optionList .options .optionName{
    font-size : 14px;
    width : 130px;
  }
  #AppendPanel .PanelContent{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y:auto;
  }

  #AppendPanel #WidgetOption .filterButton{
    height: 20px;
    width : 20px;
    padding : 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size : 13px;
  }
  #AppendPanel #WidgetOption .filterButton .icon{
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    opacity : 0.4;
  }
    
  #AppendPanel #WidgetOption .filterButton .icon:active{
    transform: scale(0.80);
    opacity : 1;
  }

  #AppendPanel #WidgetOption .filterButton.select .icon{
    opacity : 1;
  }

   #AppendPanel #SourceOption .optionButton{
    height: 20px;
    width : 20px;
    padding : 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size : 13px;
  }
  #AppendPanel #SourceOption .optionButton .icon{
    transition: transform 0.1s ease-out, opacity 0.1s ease-out;
    opacity : 0.4;
  }
    
  #AppendPanel #SourceOption .optionButton .icon:active{
    transform: scale(0.80);
    opacity : 1;
  }

  #AppendPanel #SourceOption .optionButton.select .icon{
    opacity : 1;
  }
  `;
