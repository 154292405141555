////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import AdminModulePost from "pages/lobby/space/sm_admin/AdminModulePost"
import AdminModuleWrite from "pages/lobby/space/sm_admin/AdminModuleWrite"
import AdminModuleRead from "pages/lobby/space/sm_admin/AdminModuleRead"

// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as notice from 'service/api/notice' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import * as view from 'service/api/view';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function AdminModulePostWrap() {
    // const todos = useSelector(state => state.todos)
    // const dispatch = useDispatch()
    // const addTodo = text => dispatch(addTodo(text))


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [PostRoute, setPostRoute] = useState(null); // null 목록보기 'create' 생성하기 'update' 수정하기 'read' 하나보기

    const [PostId, setPostId] = useState(null); 
    const [Postcategory, setPostcategory] = useState(null); 
    // rel 
    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////

    const listPost = () => {
        setPostId(null)
        setPostRoute(null)
        setPostcategory(null)
    }

    const readPost = (postID) => {
        setPostId(postID)
        setPostRoute('read')
    }

    const createPost = (category) => {
        setPostcategory(category)
        setPostRoute('create')
    }

    const updatePost = (postID) => {
        setPostId(postID)
        setPostRoute('update')
    }


    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    switch (PostRoute) {

        case 'create': 
            if(Postcategory != null){
                return <AdminModuleWrite category={Postcategory} postId={null} listPost={listPost}/>
            }else{
                setPostRoute(null)
                setPostId(null)
                setPostcategory(null)
                return null
            }

        case 'update': 
            if(PostId != null){
                return <AdminModuleWrite postId={PostId} category={null} listPost={listPost}/>
            }else{
                setPostRoute(null)
                setPostId(null)
                setPostcategory(null)
                return null
            }
        
        case 'read': 
            if(PostId != null){
                return <AdminModuleRead postId={PostId}  updatePost={updatePost} listPost={listPost}/>
            }else{
                setPostRoute(null)
                setPostId(null)
                setPostcategory(null)
                return null
            }
        default:
            return <AdminModulePost createPost={createPost} readPost={readPost}/>
    }
  }

  export default AdminModulePostWrap;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `
    .AdminModule {

    }

`;

const ContainerStyle = `
   .AdminModulePost{
        display: flex;
        margin : 0px 20px;
        width : 100%;
    }


    .PostField{
        display: flex;
        width : 100%;
        flex-direction: column;
    }

    .TabHeader{
    }
    

    .TabTitle{
        display flex;
        width : 100%;
        height : 50px;
        font-size : 25px;
    }
    
    .TabNav{
        display : flex;
        flex-direction: row;
        width : 100%;
        height : 50px;
        border-bottom : 4px solid #999;
        justify-content: space-between;
    }
    .TabLeft{
        display : flex;
        justify-content: start;
    }
    .TabRight{
        display : flex;
        justify-content: end;
    }

    .TabItem{
        display : flex;
        width : 100px;
        padding : 5px 0px;
        height : 40px;
        align-items: center;
        justify-content: center;
        color : #999;
    }

    .TabAction{
        display : flex;
        width : 100px;
        padding : 5px 0px;
        height : 40px;
        align-items: center;
        justify-content: center;
        color : #999;
    }
    
    .TabSelect{
        color : #fff;
        border-bottom : 4px solid #fff;
    }
    
    .post_preview{
        display : flex;
        flex-direction: column;
        width : 100%;
        height : 100px;
        margin : 20px 0px;
        border-bottom : 1px solid #999;
    }

    .post_preview:hover{
        background-color : #333;
    }

        
    .post_header{
        display : flex;
        width : 100%;
        height : 30px;
        justify-content: space-between;
    }
    
    .post_content{
        display : flex;
        width : 100%;
        font-size : 15px;
        line-height : 20px;
        height : 60px;
        overflow : hieddn;
    }

    .post_left{
        display : flex;
        width : 50%;
        align-items: center;
        justify-content: start;
        align-items: end;
    }
    .post_right{
        display : flex;
        width : 30%;
        align-items: center;
        justify-content: end;
        align-items: end;
    }

    .post_title{
        display : flex;
        width : 100%;
        flex-direction: row;
        font-size : 25px;
        line-height : 30px;
        height : 30px;
        justify-content: space-between;
    }
    
`;

